
import { defineComponent } from 'vue';
import Card from '@/Components/Shared/Card.vue';
import { mapActions } from 'vuex';
import { KycCodeEnum } from '@/Common/Enums/KycCode.enum';

export default defineComponent({
  components: {
    Card,
  },
  methods: {
    ...mapActions('app', [
      'setKyc',
    ]),
    refresh() {

      this.setKyc({ code: KycCodeEnum.Finish });

    }
  },
});
