export enum HttpExceptionCodeEnum {
  // OTP
  OTP_SERVICE001 = '01-001', // Código no encontrado
  OTP_SERVICE002 = '01-002', // Código expirado
  OTP_SERVICE003 = '01-003', // Error al validar el código
  OTP_SERVICE004 = '01-006', // El tipo de código es CHECK_AUTH o FULL_AUTH
  OTP_SERVICE005 = '01-005', // Error al generar el código
  OTP_SERVICE006 = '01-006', // Error al generar el código
  // Ailex
  AILEX_SERVICE001 = '02-001', // Error al obtener el token de AILEX
  // TOC
  TOC_SERVICE001 = '03-001', // No se encuentra usuario para firmar el comercio
  TOC_SERVICE002 = '03-002', // No se encuentra comercio para firmar
  TOC_SERVICE003 = '03-003', // El comercio no tiene administradores
  TOC_SERVICE004 = '03-004', // Tipo de administración no implementada
  // Commerce
  COMMERCE_SERVICE001 = '04-001', // El comercio ya está vinculado al usuario
  COMMERCE_SERVICE002 = '04-002', // El comercio no existe
  COMMERCE_SERVICE003 = '04-003', // El usuario no tiene comercios
  COMMERCE_SERVICE004 = '04-004', // El usuario que se quiere invitar ya esta vinculado al comercio
  COMMERCE_SERVICE005 = '04-005', // El usuario que se quiere invitar es el mismo que esta logueado
  // Auth
  AUTH_SERVICE001 = '05-001', // Error en el sign up
  AUTH_SERVICE002 = '05-002', // Contraseña incorrecta
  AUTH_SERVICE003 = '05-003', // Usuario inactivo
  AUTH_SERVICE004 = '05-004', // El usuario o refreshToken no existe
  AUTH_SERVICE005 = '05-005', // RefreshToken invalido
}
