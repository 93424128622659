
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import Timer from '@/Components/Layouts/Partials/Timer.partial.vue';
import { authService } from '@/Services/Auth.service';
import { useRouter } from 'vue-router';

export default defineComponent({
  name       : 'NavbarMenu',
  components : {
    Timer,
  },
  props: {
    showToggleIcon: { type: Boolean, default: true },
  },
  setup() {

    const store = useStore();
    const router = useRouter();
    const session = computed(() => store.state.session.session);
    const timer = computed(() => store.state.timer.timer);

    const logout = async () => {

      try{

        await authService.logout();

      }catch (error) {

        console.log(error);

      }

      if(timer.value.stop)timer.value.stop();

      const broadcast = new BroadcastChannel('tabs_channel');
      broadcast.postMessage({ command: 'redirectToSignInAllExceptMe' });

      await router.push({ name: 'sign-in' });

    };

    return { session, timer, logout };

  },
});
