
import { computed, defineComponent, reactive } from 'vue';
import Card from '@/Components/Shared/Card.vue';
import { useStore } from 'vuex';
import { kycService } from '@/Services/Kyc.service';
import { helpers, sameAs } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { KycCodeEnum } from '@/Common/Enums/KycCode.enum';
import TermsConditionsLink from '@/Components/Shared/TermsConditionsLink.vue';

export default defineComponent({
  components: {
    Card,
    TermsConditionsLink,
  },
  setup() {

    const store = useStore();

    const state = reactive({
      form: {
        consentiment: false,
      }
    });

    const rules = {
      form: {
        consentiment: {
          sameAs: helpers.withMessage('Para continuar debe aceptar los términos y condiciones', sameAs(true)),
        },
      }
    };

    const v$ = useVuelidate(rules, state);

    const enroller_code = computed(() => store.state.session.shopConfig.enroller_code);
    const commerce_tax_id = computed(() => store.state.session.shopConfig.commerce_tax_id);

    const onSubmit = async () => {

      try{

        await v$.value.$validate();
        if(!v$.value.$invalid) {

          await kycService.restartProcess({ ...state.form, enroller_code: enroller_code.value, commerce_tax_id: commerce_tax_id.value });
          await store.dispatch('app/setKyc', { code: KycCodeEnum.Finish });

        }

      }catch (error) {

        console.log(error);

      }

    };

    return {
      onSubmit,
      state,
      v$,
    };

  },
});
