
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name  : 'BreadcrumbComponent',
  props : {
    title: { type: String, default: 'title', required: false }
  },
  computed: {
    ...mapState('common', [
      'breadcrumbs'
    ])
  },
});
