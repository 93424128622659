<template>
  <div
    :id="`input-group-${id}`"
    class="row mb-3"
    :label="label"
    :label-for="`input-${id}`"
  >
    <label :for="`input-${id}`" class="mb-2">{{ label }}</label>
    <b-input-group>
      <template #append>
        <b-input-group-text @click="toggle">
          <strong class="text-dark">
            <mdicon :name="show?'eye':'eye-off'" />
          </strong>
        </b-input-group-text>
      </template>
      <b-form-input
        :id="`input-${id}`"
        :type="show?'text':'password'"
        autocomplete="off"
        placeholder="********"
        :state="state"
        @input="onChange"
      />
    </b-input-group>
    <b-form-invalid-feedback :state="state">
      {{ errors.length > 0? errors[0].$message:'' }}
    </b-form-invalid-feedback>
    <span v-if="Array.from(description).length>0" class="text-secondary font-size-13">{{ description }}</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { v4 } from 'uuid';

export default defineComponent({
  name  : 'PasswordInput',
  props : {
    label       : { type: String, required: true },
    state       : { type: Boolean, required: false },
    errors      : { type: Array, default: ()=> [], required: false },
    description : { type: String, required: false, default: '' },
  },
  emits: [ 'onChange' ],
  data() {

    return {
      show : false,
      id   : '',
    };

  },
  mounted() {

    this.id = v4();

  },
  // watch: {
  //
  //   errors: function(newVal, oldVal) {
  //
  //
  //     console.log('errors.length', newVal.length);
  //     console.log('Prop changed: ', newVal, ' | was: ', oldVal);
  //
  //   }
  //
  // },
  methods: {
    onChange(e) {

      this.$emit('onChange', e);

    },
    toggle() {

      this.show = !this.show;

    }
  }

});
</script>
<style scoped>

</style>
