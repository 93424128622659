import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row text-muted" }
const _hoisted_4 = { class: "col-9 text-start" }
const _hoisted_5 = {
  ref: "footer",
  class: "mb-0"
}
const _hoisted_6 = { class: "col-3 text-end" }
const _hoisted_7 = { class: "list-inline" }
const _hoisted_8 = { class: "list-inline-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_link = _resolveComponent("b-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, null, 512)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("ul", _hoisted_7, [
            _createElementVNode("li", _hoisted_8, [
              _createVNode(_component_b_link, {
                class: "text-muted",
                href: `mailto:${_ctx.supportEmail}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Soporte ")
                ]),
                _: 1
              }, 8, ["href"])
            ])
          ])
        ])
      ])
    ])
  ]))
}