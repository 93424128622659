import App from './App.vue';
import { createApp } from 'vue';
import BootstrapVue3 from 'bootstrap-vue-3';

import mdiVue from 'mdi-vue/v3';
import * as mdijs from '@mdi/js';
import 'animate.css';

// import 'bootstrap/dist/css/bootstrap.css';
import '@/Assets/css/app-blue.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
// import 'sweetalert2/dist/sweetalert2.min.css';

import Datepicker from '@vuepic/vue-datepicker';
import '@/Assets/css/datepicker.css';
//import '@vuepic/vue-datepicker/dist/main.css';


import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import moment from 'moment';
import router from '@/Router/Index';
import store from '@/Store/Index';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const numberFormatter = require('number-formatter');

const app = createApp(App);
app.component('DatePicker', Datepicker);
app.component('EasyDataTable', Vue3EasyDataTable);
app.use(mdiVue, { icons: mdijs });
app.provide('$store', store);
app.use(store);
app.use(router);
app.use(BootstrapVue3);

app.config.globalProperties.$filters = {
  isValid(field: any) {

    if(!field.$dirty)return null;
    return !field.$error;

  },
  dateFormat(value: string, format: string) {

    moment.locale('es');
    return moment(value).format(format);

  },
  currencyFormat(value: number) {

    return numberFormatter( '#.##0,#####', value );

  },
  getValidationClass(field: any) {

    if(!field.$dirty)return '';
    return field.$errors.length > 0? 'is-invalid': 'is-valid';

  }
};

app.mount('#app');
