import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14435dfd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "card bg-white shadow-sm border-0 ps-4 pe-4 border-radius-5 position-relative" }
const _hoisted_4 = { class: "block-layer border-radius-5" }
const _hoisted_5 = { class: "row justify-content-center" }
const _hoisted_6 = { class: "col-7" }
const _hoisted_7 = { class: "card border-light bg-gray card-balance pointer mt-6 border-radius-5" }
const _hoisted_8 = { class: "card-body d-block d-md-flex align-items-center p-3 text-center" }
const _hoisted_9 = { class: "col-sm-3 text-danger icon icon-shape icon-shape-primary icon-md b-table-sort-icon rounded-circle mb-4 mb-md-0 text-end" }
const _hoisted_10 = { class: "col text-center" }
const _hoisted_11 = { class: "h3 font-weight-bold mb-1" }
const _hoisted_12 = { class: "card-header" }
const _hoisted_13 = { class: "text-dark-black text-center font-size-20 mb-1" }
const _hoisted_14 = { class: "card-body p-0" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-md-6" }
const _hoisted_17 = { class: "col-md-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_b_link = _resolveComponent("b-link")!
  const _component_TotalCard = _resolveComponent("TotalCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_b_link, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.to('redpay')))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_mdicon, {
                          name: "alert-circle",
                          size: "74"
                        })
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("h5", _hoisted_11, [
                          _createElementVNode("small", null, _toDisplayString(_ctx.description), 1)
                        ])
                      ])
                    ])
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("p", _hoisted_13, [
            _createElementVNode("b", null, _toDisplayString(_ctx.title), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_TotalCard, {
                title: "Saldo Disponible",
                icon: "arrow-up",
                "color-bg-icon-class": "bg-warning",
                "color-text-icon-class": "text-dark"
              })
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_TotalCard, {
                title: "Saldo Contable",
                icon: "check",
                "color-bg-icon-class": "bg-dark-blue",
                "color-text-icon-class": "text-white"
              })
            ])
          ])
        ])
      ])
    ])
  ]))
}