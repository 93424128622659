<template>
  <div class="card border-light bg-white card-balance border-radius-5 pointer" style="min-height: 150px;">
    <div class="card-body text-center p-4 vertical-align-middle">
      <div class="row">
        <div class="col-xl-4 d-xl-grid align-content-xl-center d-sm-block align-items-sm-center">
          <div class="icon-md b-table-sort-icon rounded-circle icon-shape-primary icon-shape">
            <mdicon
              name="store"
              size="50"
              class="text-white"
            />
          </div>
        </div>
        <div class="col-xl-8 vertical-align-middle">
          <div class="row">
            <div class="col-md-12">
              <p class="card-text font-size-14 text-capitalize text-center mb-1 text-secondary">
                {{ shop.enroller_name }}
              </p>
              <span class="card-text font-size-14 text-capitalize text-center text-dark-blue">
                <b>{{ shop.commerce_name }}</b>
              </span><br>
              <span class="card-text font-size-14 text-center text-dark-blue">
                <b>(RUT: {{ shop.commerce_tax_id }})</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name  : 'TotalCard',
  props : {
    shop: {
      type: Object, default: () => ({})
    }
  }
});
</script>
<style scoped>
.icon-shape-primary {
  color: #0d1b48;
  background-color: rgba(13,27,72,0.1);
}
.icon-shape {
  width: 6rem;
  height: 6rem;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.45rem;
}

.card-balance{
  transition: transform .2s;
}

.card-balance:hover{
  transform: scale(1.05);
}

.block-layer{
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  /*background: rgba(255, 255, 255, 0.005);*/
  backdrop-filter: blur(2px);
  position: absolute;
  z-index: 100;
}
</style>


