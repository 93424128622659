import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { "aria-label": "breadcrumb" }
const _hoisted_2 = { class: "breadcrumb" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_link = _resolveComponent("b-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, i) => {
        return (_openBlock(), _createElementBlock("li", {
          key: i,
          class: _normalizeClass(["breadcrumb-item", item.active?'active':''])
        }, [
          (!item.active)
            ? (_openBlock(), _createBlock(_component_b_link, {
                key: 0,
                to: item.to,
                class: "text-blue"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.text), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.text), 1))
        ], 2))
      }), 128))
    ])
  ]))
}