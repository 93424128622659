export const session = {
  namespaced : true,
  state      : {
    broadcast : new BroadcastChannel('tabs_channel'),
    session   : {
      profile       : {},
      access_token  : '',
      refresh_token : '',
      deadline      : '',
    },
    shopList   : [ {} ],
    shopConfig : {
      enroller_uuid      : '',
      enroller_code      : '',
      enroller_name      : '',
      commerce_uuid      : '',
      commerce_tax_id    : '',
      commerce_tax_email : '',
      commerce_name      : '',
      commerce_hash      : '',
    }
  },
  mutations: {
    setSession(state: any, payload: any) {

      state.session = payload;

    },
    setShopConfig(state: any, payload: any) {

      state.shopConfig = payload;

    },
    setShopList(state: any, payload: any) {

      state.shopList = payload;

    },
    setBroadcast(state: any, payload: any) {

      state.broadcast = payload;

    }
  },
  actions: {
    setSession(context: any, payload: any) {

      context.commit('setSession', payload);

    },
    setShopConfig(context: any, payload: any) {

      context.commit('setShopConfig', payload);

    },
    setShopList(context: any, payload: any) {

      context.commit('setShopList', payload);

    },
    setBroadcast(state: any, payload: any) {

      state.broadcast = payload;

    },
    logout(context: any, payload: any) {

      payload.profile = {};
      payload.access_token = '';
      payload.refresh_token = '';
      payload.deadline = '';

      context.commit('setSession', payload);

    },
  },
  getters: {
    session(state: any) {

      return state.session;

    },
    shopConfig(state: any) {

      return state.shopConfig;

    },
    shopList(state: any) {

      return state.shopList;

    },
    broadcast(state: any) {

      return state.broadcast;

    },
  },
};
