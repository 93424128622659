
import { defineComponent } from 'vue';
import Navbar from '@/Components/Layouts/Partials/Navbar.partial.vue';
import OuterImage from '@/Components/Layouts/Partials/OuterImage.partial.vue';
import ShopContent from '@/Components/Layouts/Partials/ShopContent.partial.vue';
// import ShopFooter from '@/Components/Layouts/Partials/ShopFooter.partial.vue';

export default defineComponent({
  name       : 'OuterLayout',
  components : {
    Navbar,
    OuterImage,
    ShopContent,
  },
});
