import CheckEmail from '@/Components/Auth/CheckEmail.component.vue';
import LoginFom from '@/Components/Auth/LoginFom.component.vue';
import OuterLayout from '@/Components/Layouts/Outer.layout.vue';
import PasswordRecovery from '@/Components/Auth/PasswordRecovery.component.vue';
import PasswordReset from '@/Components/Auth/PasswordReset.component.vue';
import PinVerificationForm from '@/Components/Auth/PinVerificationForm.component.vue';
import RegisterForm from '@/Components/Auth/RegisterForm.component.vue';
import Unsubscribe from '@/Components/Site/Unsubscribe.component.vue';

export default {
  path      : '/site',
  name      : 'site',
  component : OuterLayout,
  children  : [
    {
      path      : 'sign-in',
      name      : 'sign-in',
      component : LoginFom,
      meta      : {
        authorize   : [],
        breadcrumbs : []
      },
    },
    {
      path      : 'sign-up',
      name      : 'sing-up',
      component : RegisterForm,
      meta      : {
        authorize   : [ 'SECURITY' ],
        breadcrumbs : []
      },
    },
    {
      path      : 'password-recovery',
      name      : 'password-recovery',
      component : PasswordRecovery,
      meta      : {
        authorize   : [],
        breadcrumbs : []
      },
    },
    {
      path      : 'password-reset',
      name      : 'password-reset',
      component : PasswordReset,
      meta      : {
        authorize   : [ 'SECURITY' ],
        breadcrumbs : []
      },
    },
    {
      path      : 'verification',
      name      : 'verification',
      component : PinVerificationForm,
      meta      : {
        authorize   : [ 'SECURITY' ],
        breadcrumbs : []
      },
    },
    {
      path      : 'check-email',
      name      : 'check-email',
      component : CheckEmail,
      meta      : {
        authorize   : [],
        breadcrumbs : []
      },
    },
    {
      path      : 'unsubscribe/:hash',
      name      : 'unsubscribe',
      component : Unsubscribe,
      meta      : {
        authorize   : [],
        breadcrumbs : []
      },
    },
  ],
};

