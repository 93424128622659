
import { defineComponent } from 'vue';
import { outerlayoutData } from '@/Data/Outerlayout.data';

export default defineComponent({
  name: 'ImageOuter',
  data() {

    return {
      ...outerlayoutData
    };

  },
});
