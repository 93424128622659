export const commonData = {
  images: {
    redPay: {
      whiteLogo: '/img/redpay/RP_BLANCO_H-TG.png'
    }
  },
  placeholder: {
    defaultEmail: 'ejemplo@mail.com',
  }
};
