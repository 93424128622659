export default [
  {
    label : 'Ancud',
    value : 'Ancud',
  },
  {
    label : 'Angol',
    value : 'Angol',
  },
  {
    label : 'Antofagasta',
    value : 'Antofagasta',
  },
  {
    label : 'Arica',
    value : 'Arica',
  },
  {
    label : 'Buin',
    value : 'Buin',
  },
  {
    label : 'Calama',
    value : 'Calama',
  },
  {
    label : 'Caldera',
    value : 'Caldera',
  },
  {
    label : 'Castro',
    value : 'Castro',
  },
  {
    label : 'Cauquenes',
    value : 'Cauquenes',
  },
  {
    label : 'Chillán',
    value : 'Chillán',
  },
  {
    label : 'Concepción',
    value : 'Concepción',
  },
  {
    label : 'Copiapó',
    value : 'Copiapó',
  },
  {
    label : 'Coronel',
    value : 'Coronel',
  },
  {
    label : 'Coyhaique',
    value : 'Coyhaique',
  },
  {
    label : 'Curanilahue',
    value : 'Curanilahue',
  },
  {
    label : 'Curicó',
    value : 'Curicó',
  },
  {
    label : 'Illapel',
    value : 'Illapel',
  },
  {
    label : 'Iquique',
    value : 'Iquique',
  },
  {
    label : 'La Ligua',
    value : 'La Ligua',
  },
  {
    label : 'La Serena',
    value : 'La Serena',
  },
  {
    label : 'La Unión',
    value : 'La Unión',
  },
  {
    label : 'Linares',
    value : 'Linares',
  },
  {
    label : 'Los Andes',
    value : 'Los Andes',
  },
  {
    label : 'Los Ángeles',
    value : 'Los Ángeles',
  },
  {
    label : 'Melipilla',
    value : 'Melipilla',
  },
  {
    label : 'Osorno',
    value : 'Osorno',
  },
  {
    label : 'Ovalle',
    value : 'Ovalle',
  },
  {
    label : 'Paine',
    value : 'Paine',
  },
  {
    label : 'Parral',
    value : 'Parral',
  },
  {
    label : 'Pichilemu',
    value : 'Pichilemu',
  },
  {
    label : 'Pucón',
    value : 'Pucón',
  },
  {
    label : 'Puerto Montt',
    value : 'Puerto Montt',
  },
  {
    label : 'Puerto Varas',
    value : 'Puerto Varas',
  },
  {
    label : 'Punta Arenas',
    value : 'Punta Arenas',
  },
  {
    label : 'Quillota',
    value : 'Quillota',
  },
  {
    label : 'Rancagua',
    value : 'Rancagua',
  },
  {
    label : 'San Antonio',
    value : 'San Antonio',
  },
  {
    label : 'San Bernardo',
    value : 'San Bernardo',
  },
  {
    label : 'San Carlos',
    value : 'San Carlos',
  },
  {
    label : 'San Felipe',
    value : 'San Felipe',
  },
  {
    label : 'San Fernando',
    value : 'San Fernando',
  },
  {
    label : 'Santa Cruz',
    value : 'Santa Cruz',
  },
  {
    label : 'Santiago',
    value : 'Santiago',
  },
  {
    label : 'Talca',
    value : 'Talca',
  },
  {
    label : 'Talcahuano',
    value : 'Talcahuano',
  },
  {
    label : 'Temuco',
    value : 'Temuco',
  },
  {
    label : 'Tocopilla',
    value : 'Tocopilla',
  },
  {
    label : 'Valdivia',
    value : 'Valdivia',
  },
  {
    label : 'Vallenar',
    value : 'Vallenar',
  },
  {
    label : 'Valparaíso',
    value : 'Valparaíso',
  },
  {
    label : 'Villa Alemana',
    value : 'Villa Alemana',
  },
  {
    label : 'Villarrica',
    value : 'Villarrica',
  },
  {
    label : 'Viña del Mar',
    value : 'Viña del Mar',
  },
];
