<template>
  <div class="col-12 mb-4">
    <div class="card border-light bg-gray border-radius-5">
      <div class="card-body d-block d-md-flex align-items-center p-3 text-center">
        <div
          class="icon icon-shape icon-shape-primary icon-md b-table-sort-icon rounded-circle me-1 mb-4 mb-md-0"
          :class="`${ colorTextIconClass } ${ colorBgIconClass }`"
        >
          <mdicon :name="icon" />
        </div>
        <div class="col text-center">
          <h5 class="h3 font-weight-bold mb-1">
            <b>{{ $filters.currencyFormat(value) }}</b><br>
            <small class="d-block h6 font-weight-normal">
              {{ title }}
            </small>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name  : 'TotalCard',
  props : {
    colorTextIconClass : { type: String, default: '' },
    colorBgIconClass   : { type: String, default: '' },
    icon               : { type: String, default: 'check' },
    title              : { type: String, default: 'Title' },
    value              : { type: Number, default: 0 },
  }
});
</script>
<style scoped>
.icon-shape-primary {
  color: #0d1b48;
  background-color: rgba(13,27,72,0.1);
}
.icon-shape {
  width: 3.90rem;
  height: 3.90rem;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.45rem;
}
</style>


