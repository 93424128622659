export const common = {
  namespaced : true,
  state      : {
    breadcrumbs : [],
    comunas     : [],
    girosSII    : [],
  },
  mutations: {
    setBreadcrumbs(state: any, payload: any) {

      state.breadcrumbs = payload;

    },
    setComunas(state: any, payload: any) {

      state.comunas = payload;

    },
    setGirosSII(state: any, payload: any) {

      state.girosSII = payload;

    },
  },
  actions: {
    setBreadcrumbs(context: any, payload: any) {

      context.commit('setBreadcrumbs', payload);

    },
    setComunas(context: any, payload: any) {

      context.commit('setComunas', payload);

    },
    setGirosSII(context: any, payload: any) {

      context.commit('setGirosSII', payload);

    }
  },
  getters: {
    breadcrumbs(state: any) {

      return state.breadcrumbs;

    },
    comunas(state: any) {

      return state.comunas;

    },
    girosSII(state: any) {

      return state.girosSII;

    },
  },
};
