export const outerlayoutData = {
  images: {
    whiteLogo: {
      src : '/img/redpay/RP_BLANCO_H.svg',
      alt : 'white-logo',
    },
    colorLogo: {
      src : '/img/redpay/logo-color.svg',
      alt : 'color-logo',
    },
  }
};
