export const auth = {
  namespaced : true,
  state      : {
    verificationAuth: {
      security_token : '',
      payload        : {

      },
    }
  },
  mutations: {
    setVerificationAuth(state: any, payload: any) {

      state.verificationAuth = payload;

    },
  },
  actions: {
    setVerificationAuth(context: any, payload: any) {

      context.commit('setVerificationAuth', payload);

    },
  },
  getters: {
    getVerificationAuth(state: any) {

      return state.verificationAuth;

    }
  }
};
