import SurplusStatement from '@/Components/Statement/SurplusStatement.component.vue';
import ChargebackStatement from '@/Components/Statement/ChargebackStatement.component.vue';
import MainLayout from '@/Components/Layouts/Main.layout.vue';
import RedpayStatement from '@/Components/Statement/RedpayStatement.component.vue';

export default {
  path      : '/Statement',
  name      : 'statement',
  component : MainLayout,
  children  : [
    {
      path      : '/:commerce_hash/cartola/excentes',
      name      : 'surplus',
      component : SurplusStatement,
      meta      : {
        authorize   : [ 'USER' ],
        breadcrumbs : [
          {
            text   : 'Mis Comercios',
            to     : '/shop/list',
            active : false
          },
          {
            text   : 'Cartola de Excedentes',
            active : true
          },
        ]
      },
    },
    {
      path      : '/:commerce_hash/cartola/devoluciones',
      name      : 'chargeback',
      component : ChargebackStatement,
      meta      : {
        authorize   : [ 'USER' ],
        breadcrumbs : [
          {
            text   : 'Mis Comercios',
            to     : '/shop/list',
            active : false
          },
          {
            text   : 'Cartola de Devoluciones',
            active : true
          },
        ]
      },
    },
    {
      path      : '/:commerce_hash/cartola/redpay',
      name      : 'redpay',
      component : RedpayStatement,
      meta      : {
        authorize   : [ 'USER' ],
        breadcrumbs : [
          {
            text   : 'Mis Comercios',
            to     : '/shop/list',
            active : false
          },
          {
            text   : 'Cartola RedPay',
            active : true
          },
        ]
      },
    },
  ],
};
