<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label">Filtrar por: </label>
        </div>
        <div class="col-auto">
          <date-picker
            ref="datepicker"
            v-model="month"
            :dark="false"
            month-name-format="short"
            placeholder="Mes/Año"
            month-picker
            :format="format"
            auto-apply
            :close-on-auto-apply="true"
            locale="es"
            @cleared="handleOnClear"
            @update:model-value="handleUpdate"
          >
            <template #action-preview="" />
            <template #month-overlay="{ value }">
              {{ monthLabels[value] }}
            </template>
          </date-picker>
        </div>
        <div class="col-auto">
          <b-button class="w-100 btn-lg btn-redpay" @click="clear()">
            Limpiar
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import moment from 'moment';

export default defineComponent({
  name  : 'DateInput',
  props : {

  },
  emits: [ 'onClear', 'onChange' ],
  data() {

    return {
      month       : '',
      monthLabels : [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ]
    };

  },
  methods: {
    handleUpdate(date) {

      if(date) this.$emit('onChange', date);

    },
    handleOnClear() {

      this.$emit('onClear');

    },
    clear() {

      this.$refs.datepicker.clearValue();

    },
    format(date) {

      return moment(date).format('MM/YYYY');
      // return `${this.monthLabels[date.month]}/${date.year}`;

    }

  }
});

</script>
