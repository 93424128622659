import ApiServiceUriList from '@/Data/ApiServiceUriList.data';
import { httpClient } from '@/Common/AxiosClient';
import { HttpClient } from '@/Common/HttpClient';
import { ISecurityJwt } from '@/Common/Interfaces/SecurityJwt.interface';
import { ISendCodeResponse } from '@/Common/Interfaces/SendCodeResponse.interface';
import { IShop } from '@/Common/Interfaces/Shop.interface';
import jwt_decode from 'jwt-decode';

class ShopService {

  constructor(private httpClient: HttpClient) {}

  public async list(): Promise<IShop[]> {

    const { data } = await this.httpClient.get(ApiServiceUriList.Shop.List);
    return data;

  }

  public async add(payload: any, security_token: string): Promise<void> {

    await this.httpClient.post(ApiServiceUriList.Shop.Add, { ...payload, security_token });

  }

  public async validate(payload: any): Promise<ISendCodeResponse> {

    const { data } : { data: ISecurityJwt } = await this.httpClient.post(ApiServiceUriList.Shop.Validate, payload);

    return {
      security_token : data.security_token,
      payload        : jwt_decode(data.security_token)
    };

  }

}

export const shopService = new ShopService(httpClient);
