
import { defineComponent } from 'vue';
import { authService } from '@/Services/Auth.service';
import { mapActions } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import Card from '@/Components/Shared/Card.vue';
import TextInput from '@/Components/Shared/TextInput.vue';
import { CodeTypeEnum } from '@/Common/Enums/CodeType.enum';
import { commonData } from '@/Data/Common.data';
import { ErrorManagement } from '@/Common/ErrorManagement';

export default defineComponent({
  name       : 'PasswordRecovery',
  components : {
    Card,
    TextInput,
  },
  setup () {

    return {
      v$: useVuelidate()
    };

  },
  data() {

    return {
      ...commonData,
      form: {
        email : '',
        type  : CodeTypeEnum.RECOVERY_PASSWORD,
      }
    };

  },
  validations() {

    return {
      form: {
        email: {
          required : helpers.withMessage('', required),
          email    : helpers.withMessage('Email inválido', email),
        },
      }
    };

  },
  methods: {
    ...mapActions('auth', [
      'setVerificationAuth'
    ]),
    async onSubmit() {

      try {

        await this.v$.$validate();
        if(!this.v$.$invalid) {

          const response = await authService.sendCode(this.form);
          await this.setVerificationAuth(response);

          this.$router.push('/site/password-reset');

        }

      }catch (error) {

        new ErrorManagement({ error });

      }

    },
    onChange(e: any, field: string) {

      this.form = { ...this.form, [field]: e };

    }
  }

});
