
import { defineComponent } from 'vue';
import { authService } from '@/Services/Auth.service';
import useVuelidate from '@vuelidate/core';
import { required, helpers, sameAs, minLength } from '@vuelidate/validators';
import Card from '@/Components/Shared/Card.vue';
import PasswordInput from '@/Components/Shared/PasswordInput.vue';
import PinInput from '@/Components/Shared/PinInput.vue';
import { mapState } from 'vuex';
import { Helper } from '@/Common/Helper';
import { ErrorManagement } from '@/Common/ErrorManagement';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Swal = require('sweetalert2/dist/sweetalert2.js');

export default defineComponent({
  name       : 'RegisterForm',
  components : {
    PinInput,
    Card,
    PasswordInput,
  },
  setup () {

    return {
      v$: useVuelidate()
    };

  },
  data() {

    return {
      form: {
        code            : '',
        name            : '',
        password        : '',
        confirmPassword : ''
      }
    };

  },
  validations() {

    return {
      form: {
        code: {
          required  : helpers.withMessage('', required),
          minLength : helpers.withMessage('Código incompleto', minLength(6)),
        },
        name     : { required },
        password : {
          required         : helpers.withMessage('', required),
          passwordValidate : helpers.withMessage('', Helper.passwordValidate),
        },
        confirmPassword: {
          required       : helpers.withMessage('', required),
          sameAsPassword : helpers.withMessage('No coincide con la contraseña', sameAs(this.form.password)),
        },
      }
    };

  },
  computed: {
    ...mapState('auth', [
      'verificationAuth'
    ])
  },
  methods: {
    async onSubmit() {

      try{

        await this.v$.$validate();
        if(!this.v$.$invalid) {

          const { code, name, password } = this.form;
          await authService.signUp({ code, name, password }, this.verificationAuth.security_token);

          await Swal.fire({
            title             : '',
            text              : 'Tu cuenta ha sido creada exitosamente',
            icon              : 'success',
            confirmButtonText : 'OK'
          });

          this.$router.push('/site/sign-in');

        }

      }catch (error) {

        const pinInput:any = this.$refs.pin;
        new ErrorManagement({ error, callback: (result: any) => {

          if (result.isConfirmed) pinInput.resend();
          if (result.isDismissed) pinInput.clearInput();

        } });

      }

    },
    onChange(e: any, field: string) {

      this.form = { ...this.form, [field]: e };

    }
  }
});
