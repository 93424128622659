
import { defineComponent } from 'vue';
import Navbar from './Partials/Navbar.partial.vue';
import Sidebar from './Partials/Sidebar.partial.vue';
import Footer from './Partials/Footer.partial.vue';
import Breadcrumb from '@/Components/Shared/Breadcrumb.vue';

export default defineComponent({
  name       : 'MainLayout',
  components : {
    Breadcrumb,
    Navbar,
    Sidebar,
    Footer
  },
});
