import ApiServiceUriList from '@/Data/ApiServiceUriList.data';
import { httpClient } from '@/Common/AxiosClient';
import { HttpClient } from '@/Common/HttpClient';
import { IGetBalanceRequest } from '@/Common/Interfaces/GetBalanceRequest.interface';
import { IGetBalanceResponse } from '@/Common/Interfaces/GetBalanceResponse.interface';
import { IGetStatementRequest } from '@/Common/Interfaces/GetStatementRequest.interface';
import { IGetStatementResponse } from '@/Common/Interfaces/GetStatementResponse.interface';

class AccountService {

  constructor(private httpClient: HttpClient) { }

  public async getBalance(payload: IGetBalanceRequest): Promise<IGetBalanceResponse> {

    const { data } : { data: IGetBalanceResponse } = await this.httpClient.post(ApiServiceUriList.Account.GetBalance, payload);
    return data;

  }

  public async getStatement(payload: IGetStatementRequest): Promise<IGetStatementResponse> {

    // let url = `${ApiServiceUriList.Account.GetStatement}/${ payload.enroller_code }/${ payload.commerce_tax_id }/${ payload.statement_type }?`;
    let url = `${ApiServiceUriList.Account.GetStatement}?`;

    const {
      current_page,
      item_per_page,
      from,
      to,
      status,
      enroller_code,
      commerce_tax_id,
      statement_type } = payload;

    url += current_page?`current_page=${current_page}&`:'';
    url += item_per_page?`item_per_page=${item_per_page}&`:'';
    url += from?`from=${from}&`:'';
    url += to?`to=${to}&`:'';
    url += status.length>0?`status=${status.join(',')}&`:'';

    const { data } : { data: IGetStatementResponse } = await this.httpClient.post(url, { enroller_code, commerce_tax_id, statement_type } );
    return data;

  }

}

export const accountService = new AccountService(httpClient);
