import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "redpay-statement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_KycRequestForm = _resolveComponent("KycRequestForm")!
  const _component_KycWaiting = _resolveComponent("KycWaiting")!
  const _component_KycFileRequestForm = _resolveComponent("KycFileRequestForm")!
  const _component_KycRequestSignatureForm = _resolveComponent("KycRequestSignatureForm")!
  const _component_KycInvalid = _resolveComponent("KycInvalid")!
  const _component_KycInvalidFaculty = _resolveComponent("KycInvalidFaculty")!
  const _component_KycInvalidWithoutAction = _resolveComponent("KycInvalidWithoutAction")!
  const _component_KycInvalidNotImplemented = _resolveComponent("KycInvalidNotImplemented")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.kyc.code === _ctx.KycCodeEnum.Finish)
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 0,
          title: "Cartola RedPay",
          type: "redpay"
        }))
      : _createCommentVNode("", true),
    (_ctx.kyc.code === _ctx.KycCodeEnum.KycRequired)
      ? (_openBlock(), _createBlock(_component_KycRequestForm, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.kyc.code === _ctx.KycCodeEnum.AilexRequested)
      ? (_openBlock(), _createBlock(_component_KycWaiting, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.kyc.code === _ctx.KycCodeEnum.FileRequired)
      ? (_openBlock(), _createBlock(_component_KycFileRequestForm, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.kyc.code === _ctx.KycCodeEnum.PendingSignatures)
      ? (_openBlock(), _createBlock(_component_KycRequestSignatureForm, { key: 4 }))
      : _createCommentVNode("", true),
    (_ctx.kyc.code === _ctx.KycCodeEnum.Invalid)
      ? (_openBlock(), _createBlock(_component_KycInvalid, { key: 5 }))
      : _createCommentVNode("", true),
    (_ctx.kyc.code === _ctx.KycCodeEnum.InvalidFaculty)
      ? (_openBlock(), _createBlock(_component_KycInvalidFaculty, { key: 6 }))
      : _createCommentVNode("", true),
    (_ctx.kyc.code === _ctx.KycCodeEnum.InvalidWithoutActions)
      ? (_openBlock(), _createBlock(_component_KycInvalidWithoutAction, { key: 7 }))
      : _createCommentVNode("", true),
    (_ctx.kyc.code === _ctx.KycCodeEnum.InvalidNotImplemented)
      ? (_openBlock(), _createBlock(_component_KycInvalidNotImplemented, { key: 8 }))
      : _createCommentVNode("", true)
  ]))
}