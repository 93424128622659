
import { defineComponent } from 'vue';
import Breadcrumb from '@/Components/Shared/Breadcrumb.vue';

export default defineComponent({
  name       : 'OuterShopContent',
  components : {
    Breadcrumb
  },
  mounted() {

    const text = process.env.VUE_APP_FOOTER_TEXT || '';
    const footer = this.$refs.footer as HTMLElement;
    const bice = '<strong> Banco BICE </strong>';
    const newText = text.replace(/Banco BICE/g, bice) + '&copy;';
    footer.innerHTML = newText;

  }
});
