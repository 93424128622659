
import DataTable from '../Shared/DataTable.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name       : 'RedpayStatement',
  components : {
    DataTable,
  }
});
