
import { computed, ComputedRef, defineComponent, onMounted, ref } from 'vue';
import Card from '@/Components/Shared/Card.vue';
import FileInputForm from '@/Components/Kyc/Partials/FileInputForm.vue';
import { kycService } from '@/Services/Kyc.service';
import { useStore } from 'vuex';
import { IKycGetDocumentsResponse } from '@/Common/Interfaces/KycGetDocumentsResponse.interface';
import { KycCodeEnum } from '@/Common/Enums/KycCode.enum';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Swal = require('sweetalert2/dist/sweetalert2.js');

export default defineComponent({
  name       : 'KycFileRequestForm',
  components : {
    Card,
    FileInputForm,
  },
  setup() {

    const store = useStore();

    const documents = ref<IKycGetDocumentsResponse[]>([]);
    const enroller_code: ComputedRef<string> = computed(() => store.getters['session/shopConfig'].enroller_code);
    const commerce_tax_id: ComputedRef<string> = computed(() => store.getters['session/shopConfig'].commerce_tax_id);

    const setKyc = (kycCodeEnum: KycCodeEnum) => {

      store.dispatch('app/setKyc', { code: kycCodeEnum });

    };

    const getDocuments = async () => {

      try{

        documents.value = await kycService.getDocuments({ enroller_code: enroller_code.value, commerce_tax_id: commerce_tax_id.value });

      }catch (error) {

        console.error(error);

      }

    };

    const updateState = (name: string) => {

      const file: any = documents.value.findIndex((file: IKycGetDocumentsResponse) => (file.url == name));
      documents.value[file].is_sent = true;

      const options: any = {
        title             : '',
        text              : 'El documento fue enviado con éxito!',
        icon              : 'success',
        confirmButtonText : 'OK',
      };

      if(documents.value.every((document) => document.is_sent)) {

        options.willClose = () => setKyc(KycCodeEnum.Finish);

      }

      Swal.fire(options);

    };

    onMounted(async () => {

      await getDocuments();

    });

    return { documents, updateState };

  },
});
