import { TaskTimer } from 'tasktimer';

export const app = {
  namespaced : true,
  state      : {
    showLoader : false,
    kyc        : {
      code : '',
      time : '',
    },
    inactivityTime      : new Date().getTime(),
    timer               : 0,
    timerLabel          : '',
    signatureStateTimer : new TaskTimer(10000),
  },
  mutations: {
    setShowLoader(state: any, payload: any) {

      state.showLoader = payload;

    },
    setKyc(state: any, payload: any) {

      state.kyc = payload;

    },
    setSignatureState(state: any, payload: any) {
        
      state.signatureStateTimer = payload;

    }
  },
  actions: {
    setShowLoader(context: any, payload: any) {

      context.commit('setShowLoader', payload);

    },
    setKyc(context: any, payload: any) {

      context.commit('setKyc', payload);

    },
    setSignatureState(context: any, payload: any) {
        
      context.commit('setSignatureState', payload);

    }
  },
  getters: {
    loader(state: any) {

      return state.showLoader;

    },
    kyc(state: any) {

      return state.kyc;

    },
    signatureState(state: any) {
        
      return state.signatureStateTimer;

    }
  }
};
