<template>
  <b-link :to="to" class="text-decoration-none">
    <div class="card bg-white shadow-sm border-0 ps-4 pe-4 border-radius-5 hover-transform pointer">
      <div class="card-header">
        <p class="text-dark-black text-center font-size-20 mb-1">
          <b>{{ title }}</b>
        </p>
      </div>
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6">
            <TotalCard
              title="Saldo Disponible"
              icon="arrow-up"
              color-bg-icon-class="bg-warning"
              color-text-icon-class="text-dark"
              :value="balance.available_balance"
            />
          </div>
          <div class="col-md-6">
            <TotalCard
              title="Saldo Contable"
              icon="check"
              color-bg-icon-class="bg-dark-blue"
              color-text-icon-class="text-white"
              :value="balance.countable_balance"
            />
          </div>
        </div>
      </div>
    </div>
  </b-link>
</template>

<script>
import { defineComponent } from 'vue';
import TotalCard from '@/Components/Home/Partials/TotalCard.partial';

export default defineComponent({
  name       : 'BalanceCard',
  components : {
    TotalCard
  },
  props: {
    title   : { type: String, default: 'Title' },
    balance : { type: Object, required: true },
    to      : { type: Object, default: () => ({}) },
  },
});
</script>

