import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-dashboard" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-xl-6" }
const _hoisted_4 = { class: "col-xl-6" }
const _hoisted_5 = { class: "col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalanceCard = _resolveComponent("BalanceCard")!
  const _component_ActivationCard = _resolveComponent("ActivationCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isActivated)
          ? (_openBlock(), _createBlock(_component_BalanceCard, {
              key: 0,
              title: "Cuenta RedPay",
              balance: _ctx.redPayBalance,
              to: { name: 'redpay' }
            }, null, 8, ["balance"]))
          : (_openBlock(), _createBlock(_component_ActivationCard, {
              key: 1,
              title: "Cuenta RedPay",
              description: "¡Activa tu Cuenta RedPay!"
            }))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BalanceCard, {
          title: "Cuenta de Devolución",
          balance: _ctx.chargeBackBalance,
          to: { name: 'chargeback' }
        }, null, 8, ["balance"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BalanceCard, {
          title: "Cuenta de Excedentes",
          balance: _ctx.surplusBalance,
          to: { name: 'surplus' }
        }, null, 8, ["balance"])
      ])
    ])
  ]))
}