
import type { Header, Item, ServerOptions } from 'vue3-easy-data-table';
import { computed, ComputedRef, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { accountService } from '@/Services/Account.service';
import { MovementEnum } from '@/Common/Interfaces/GetStatementRequest.interface';
import DateInput from '@/Components/Shared/DateInput.vue';
import moment from 'moment/moment';
import { KycCodeEnum } from '@/Common/Enums/KycCode.enum';
import { IShop } from '@/Common/Interfaces/Shop.interface';

export default defineComponent({
  name       : 'DataTable3',
  components : {
    DateInput,
  },
  props: {
    title : { type: String, default: 'Title' },
    type  : { type: String, default: 'Type' },
  },
  setup(props: any) {

    const captureHeaders: Header[] = [
      { text: 'Fecha', value: 'committed_at' },
      { text: 'Descripción', value: 'description' },
      { text: 'Abono', value: 'credit_amount' },
      { text: 'Cargo', value: 'debit_amount' },
    ];

    const headers: Header[] = [
      { text: 'Fecha', value: 'committed_at' },
      { text: 'Descripción', value: 'description' },
      { text: 'Abono', value: 'credit_amount' },
      { text: 'Cargo', value: 'debit_amount' },
      { text: 'Saldo', value: 'available_balance_after' },
    ];

    const items = ref<Item[]>([]);
    const captures = ref<Item[]>([]);

    // importante: si no se define el loading y si no se realiza el cambio de estado de este no funciona la paginación
    const loading = ref(false);
    const serverItemsLength = ref(0);
    const serverOptions = ref<ServerOptions>({
      page        : 1,
      rowsPerPage : 10,
      sortBy      : 'age',
      sortType    : 'desc',
    });

    const rangeDate = ref({
      start : '',
      end   : '',
    });

    const store = useStore();
    const shop: ComputedRef<IShop> = computed(() => store.state.session.shopConfig);

    const portalReversaUrl = computed(() => process.env.VUE_APP_PORTAL_REVERSA_URL || '#');
    const kyc = computed(() => store.state.app.kyc);
    const kycCodeEnum = computed(() => KycCodeEnum);

    const showLoader = ref(false);

    const loadFromServer = async () => {

      showLoader.value = false;
      // importante: si no se define el loading y si no se realiza el cambio de estado de este no funciona la paginación
      loading.value = true;
      const { data, total_item_count } = await accountService.getStatement({
        commerce_tax_id : shop.value.commerce_tax_id,
        enroller_code   : shop.value.enroller_code,
        current_page    : serverOptions.value.page,
        item_per_page   : serverOptions.value.rowsPerPage,
        from            : rangeDate.value.start,
        to              : rangeDate.value.end,
        status          : [ MovementEnum.COMMITTED, MovementEnum.CAPTURED ],
        statement_type  : props.type,
      });

      items.value = data.filter( (i) => [ MovementEnum.COMMITTED ].includes(i.status as MovementEnum) );
      captures.value = data.filter( (i) => [ MovementEnum.CAPTURED ].includes(i.status as MovementEnum) );

      serverItemsLength.value = total_item_count;

      loading.value = false;
      showLoader.value = true;

    };

    // initial load
    loadFromServer();

    const onChangeDatePicker = (date: any) => {

      const selectedDate = moment(new Date(date.year, date.month));
      const dateStart = selectedDate.clone().set({
        hour        : 0,
        minute      : 0,
        second      : 0,
        millisecond : 0,
      }).format();

      const dateEnd = selectedDate.clone().add(1, 'months').add(-1, 'millisecond').format();

      rangeDate.value = {
        start : dateStart,
        end   : dateEnd
      };

      serverOptions.value.page = 1;
      loadFromServer();

    };

    const onClearDatePicker = () => {

      rangeDate.value = {
        start : '',
        end   : ''
      };

      serverOptions.value.page = 1;
      loadFromServer();

    };

    watch(serverOptions, () => loadFromServer(), { deep: true });

    return {
      headers,
      items,
      serverOptions,
      serverItemsLength,
      loading,
      onClearDatePicker,
      onChangeDatePicker,
      rangeDate,
      captures,
      captureHeaders,
      showLoader,
      portalReversaUrl,
      kyc,
      kycCodeEnum,
    };

  }
});

