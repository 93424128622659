
import { defineComponent } from 'vue';
import { outerlayoutData } from '@/Data/Outerlayout.data';

export default defineComponent({
  name: 'OuterContent',
  data() {

    return {
      ...outerlayoutData
    };

  },
  computed: {
    supportEmail() {

      return process.env.VUE_APP_SUPPORT_EMAIL || '';

    },
  },
  mounted() {

    const text = process.env.VUE_APP_FOOTER_TEXT || '';
    const footer = this.$refs.footer as HTMLElement;
    const bice = '<strong> Banco BICE </strong>';
    const newText = text.replace(/Banco BICE/g, bice) + '&copy;';
    footer.innerHTML = newText;

  }
});
