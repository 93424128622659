
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name  : 'TermsConditionsLink',
  props : {
    label     : { type: String, default: 'Términos y Condiciones' },
    className : { type: String, default: 'text-muted' },
  },
  setup() {

    const pathPDF = computed(() => process.env.VUE_APP_PATH_TO_TYC_PDF);

    return {
      pathPDF
    };

  }
});
