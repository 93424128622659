
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { authService } from '@/Services/Auth.service';

export default defineComponent({
  name: 'UnsubscribeComponent',
  setup() {

    const router = useRouter();
    const status = ref(false);

    const params: any = router.currentRoute.value.params;

    const unsubscribe = async () => {

      try{

        status.value = true;
        await authService.unsubscribe(params.hash);


      } catch (error) {

        status.value = false;

      }

    };


    onMounted(() => {

      unsubscribe().then((response) => {

        console.log(response);

      });

    });

    return {
      status
    };

  }
});
