export enum KycCodeEnum {
  KycRequired = 'KYC_REQUIRED',
  AilexRequested = 'AILEX_REQUESTED',
  FileRequired = 'FILE_REQUIRED',
  PendingSignatures = 'PENDING_SIGNATURE',
  Finish = 'FINISH',
  Invalid = 'INVALID',
  InvalidFaculty = 'INVALID_FACULTY',
  InvalidWithoutActions = 'INVALID_WITHOUT_ACTIONS',
  InvalidNotImplemented = 'INVALID_NOT_IMPLEMENTED',
}
