import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import CommonRouter from '@/Router/Common.router';
import FaqRouter from '@/Router/Faq.router';
import ShopRouter from '@/Router/Shop.router';
import SiteRouter from '@/Router/Site.router';
import StatementRouter from '@/Router/Statement.router';
import store from '@/Store/Index';
import Page404 from '@/Components/Error/Page404.component.vue';
import { IShop } from '@/Common/Interfaces/Shop.interface';

const routes: Array<RouteRecordRaw> = [
  CommonRouter,
  SiteRouter,
  ShopRouter,
  StatementRouter,
  FaqRouter,
  { path: '/:pathMatch(.*)*', component: Page404 },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {

  const { authorize } = to.meta;

  await store.dispatch('common/setBreadcrumbs', to.meta?.breadcrumbs);

  const session = store.getters['session/session'];
  const verification = store.getters['auth/getVerificationAuth'];

  if (Array.isArray(authorize)) {

    if (authorize.includes('USER') && !session.access_token) {

      return next({ name: 'sign-in' });

    }

    if (authorize.includes('SECURITY') && !verification.security_token) {

      return next({ name: 'sign-in' });

    }

    if(to.params.commerce_hash) {

      const commerce_hash = to.params.commerce_hash as string;
      const shopList: IShop[] = store.getters['session/shopList'];
      const shop = shopList.find((shop: IShop) => (shop.commerce_hash == commerce_hash)) as IShop;
      if(!shop) return next({ name: 'list' });
      await store.dispatch('session/setShopConfig', shop);

    }

  }

  if(!authorize) {

    if (session.access_token) {

      return next({ name: 'list' });

    }

  }

  return next();

});

export default router;
