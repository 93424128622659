import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "card bg-white shadow-sm border-0 ps-4 pe-4 pb-3 border-radius-5 animate__animated animate__flipInX" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "card-title text-center text-md-end mb-4 mt-3 font-size-18 text-dark-black" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, [
            _createElementVNode("b", null, _toDisplayString(_ctx.title), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "content")
            ])
          ])
        ])
      ])
    ])
  ]))
}