
import { defineComponent } from 'vue';
import TotalCard from '@/Components/Home/Partials/TotalCard.partial.vue';

export default defineComponent({
  name       : 'ActivationCard',
  components : {
    TotalCard
  },
  props: {
    title       : { type: String, default: 'Title' },
    description : { type: String, default: 'Description' },
  },
  computed: {
    enroller_code() {

      return this.$route.params.enroller_code || this.$store.getters['session/shopConfig'].enroller_code;

    },
    commerce_tax_id() {

      return this.$route.params.commerce_tax_id || this.$store.getters['session/shopConfig'].commerce_tax_id;

    }
  },
  methods: {
    to(namePath: string) {

      this.$router.push({ name: namePath, params: { enroller_code: this.enroller_code, commerce_tax_id: this.commerce_tax_id } });

    }
  }
});
