import store from '@/Store/Index';
import { Store } from 'vuex';
import ApiServiceUriList from '@/Data/ApiServiceUriList.data';

export default class LoaderManagement {

  constructor(private store: Store<any>) {}

  async setLoader(uri = '', show: boolean) {

    switch (uri) {
      case ApiServiceUriList.Auth.Logout:
      case ApiServiceUriList.Kyc.CheckSignatures:
      case ApiServiceUriList.Auth.RefreshSession:
        // listado de enpoints en los cuales no se mostrará el loader
        break;
      default:
        await this.store.dispatch('app/setShowLoader', show);      
        break;
    }

  }

  async setRefreshControl(uri = '') {

    switch (uri) {
      case ApiServiceUriList.Auth.Logout:
      case ApiServiceUriList.Kyc.CheckSignatures:      
        // listado de enpoints en los cuales no se realizará el refresh del tiempo de inactividad
        break;
      default: {

        const broadcast = new BroadcastChannel('tabs_channel');
        broadcast.postMessage({ command: 'refreshCountdownAllExceptMe' });

        await this.store.dispatch('timer/setInactivityTime', new Date().getTime());
        
      }         
        break;
    }

  }

}

export const loaderManagement = new LoaderManagement(store);
