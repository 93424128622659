export enum MovementEnum {
  CAPTURED = 'CAPTURED',
  COMMITTED = 'COMMITTED',
  RELEASED = 'RELEASED',
}

export enum StatementType {
  Surplus='surplus',
  Chargeback='reversa',
  RedPay='redpay',
}

export interface IGetStatementRequest {
  enroller_code: string;
  commerce_tax_id: string;
  current_page?: number;
  item_per_page?: number;
  from?: string;
  to?: string;
  status: MovementEnum[];
  statement_type: StatementType;
}
