import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-460c12cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "outer-layout",
  class: "bg-light"
}
const _hoisted_2 = { class: "container-fluid main-container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-7 shop-content" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OuterImage = _resolveComponent("OuterImage")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_ShopContent = _resolveComponent("ShopContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_OuterImage, { class: "col-md-5" }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Navbar, { "show-toggle-icon": false }),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ShopContent)
            ])
          ])
        ])
      ])
    ])
  ]))
}