import ApiServiceUriList from '@/Data/ApiServiceUriList.data';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Store } from 'vuex';
import { HttpClient } from '@/Common/HttpClient';
import { IHttpClient } from '@/Common/Interfaces/HttpClient.interface';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import store from '@/Store/Index';
import { Helper } from '@/Common/Helper';
import { ErrorManagement } from '@/Common/ErrorManagement';
import router from '@/Router/Index';
import LoaderManagement from './LoaderManagement';

export class AxiosClient extends HttpClient {

  private readonly http: AxiosInstance;
  private readonly baseURL: string;
  private readonly store: Store<any>;
  private readonly loader: LoaderManagement;
  private sending: boolean;

  constructor({ baseURL, store, loaderManagement }: IHttpClient) {

    super();

    this.baseURL = baseURL;
    this.sending = false;
    this.loader = loaderManagement;
    this.store = store;

    this.http = axios.create({
      baseURL: this.baseURL
    });

    this.configuration();

  }

  private configuration() {

    this.http.interceptors.request.use(
      async (request: any) => {
        
        await this.loader.setLoader(request.url, true);
        const session = this.store.getters['session/session'];

        if(session.access_token) {

          const decoded: any = jwt_decode(session.access_token);

          const currentTime = moment().utc();
          const deadline = moment(session.deadline).utc();
          const expirationDate = moment.unix(decoded.exp).utc();

          if(currentTime.isAfter(deadline)) {

            await this.store.dispatch('session/logout', {});
            router.push({ name: 'sign-in' });
            return request;

          }else if(currentTime.isSameOrAfter(expirationDate) && !this.sending) {

            try{

              this.sending = true;

              const { data }: any = await this.http({
                method : 'post',
                url    : ApiServiceUriList.Auth.RefreshSession,
                data   : {
                  authorization_refresh: session.refresh_token,
                },
              });

              await this.store.dispatch('session/setSession', {
                ...session,
                access_token: data.access_token,
              });

              session.access_token = data.access_token;

              this.sending = false;

            }catch(error) {

              return request;

            }

          }

        }

        request.headers = { ...request.headers, Authorization: `Bearer ${ session.access_token }` };

        return request;

      },
      async (err: AxiosError) => {

        await this.loader.setLoader(err.request.url, false);
        return Promise.reject(err);

      }
    );

    this.http.interceptors.response.use(
      async (request: AxiosResponse) => {        

        const uri = request.config.url ? Helper.getUriFromUrl(request.config.url): '';
        
        await this.loader.setRefreshControl(uri);
        await this.loader.setLoader(uri, false);

        return request;

      },
      async (err: any) => {      

        const broadcast = new BroadcastChannel('tabs_channel');
        broadcast.postMessage({ command: 'refreshCountdownAllExceptMe' });

        await this.loader.setLoader(err.request.url, false);
        await this.store.dispatch('timer/setInactivityTime', new Date().getTime());

        new ErrorManagement({ error: err });        

        return Promise.reject(err);

      }
    );

  }

  public async post(url: string, payload?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {

    return await this.http( {
      method : 'post',
      url    : url,
      data   : payload,
      ...config
    });

  }

  public async get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {

    return await this.http({
      method : 'get',
      url    : url,
      ...config
    });

  }


  public async put(url: string, payload: object, config?: AxiosRequestConfig): Promise<AxiosResponse> {

    return await this.http( {
      method : 'put',
      url    : url,
      data   : payload,
      ...config
    });

  }

}

export const httpClient = new AxiosClient({
  baseURL          : <string>process.env.VUE_APP_BASE_URL,
  store            : store,
  loaderManagement : new LoaderManagement(store),
});
