import ApiServiceUriList from '@/Data/ApiServiceUriList.data';
import { httpClient } from '@/Common/AxiosClient';
import { HttpClient } from '@/Common/HttpClient';
import { IKycUploadDocumentRequest } from '@/Common/Interfaces/KycUploadDocumentRequest.interface';
import { IKycInitRequest } from '@/Common/Interfaces/KycInitRequest.interface';
import { IKycGetJobIdResponse } from '@/Common/Interfaces/KycJobIdResponse.interface';
import { IKycGetDocumentsResponse } from '@/Common/Interfaces/KycGetDocumentsResponse.interface';
import { IKycUploadDocumentResponse } from '@/Common/Interfaces/KycUploadDocumentResponse.interface';
import { IParticipant } from '@/Common/Interfaces/IParticipant.interface';
import { ISendInvitationRequest } from '@/Common/Interfaces/SendInvitationRequest.interface';
import { IKycRestartProcess } from '@/Common/Interfaces/KycRestartProcess.interface';
import { IKycGetParticipantsResponse } from '@/Common/Interfaces/KycGetParticipantsResponse.interface';
import { IKycGetJobIdRequest } from '@/Common/Interfaces/KycGetJobIdResquest.interface';
import { IGetDocumentsRequest } from '@/Common/Interfaces/GetDocumentsRequest.interface';
import { IGetParticipantsRequest } from '@/Common/Interfaces/GetParticipantsRequest.interface';

class KycService {

  constructor(private httpClient: HttpClient) {}

  public async init(payload: IKycInitRequest): Promise<void> {

    const { data } = await this.httpClient.post(ApiServiceUriList.Kyc.Init, payload);
    return data;

  }

  public async getJobId(payload: IKycGetJobIdRequest): Promise<IKycGetJobIdResponse> {

    const { data } = await this.httpClient.post(ApiServiceUriList.Kyc.GetJobId, payload);
    return data;

  }

  public async getDocuments(payload: IGetDocumentsRequest): Promise<IKycGetDocumentsResponse[]> {

    const { data } = await this.httpClient.post(ApiServiceUriList.Kyc.GetDocuments, payload);
    return data;

  }

  public async uploadDocument(payload: IKycUploadDocumentRequest): Promise<IKycUploadDocumentResponse> {

    const formData = new FormData();
    formData.append('enroller_code', payload.enroller_code);
    formData.append('commerce_tax_id', payload.commerce_tax_id);
    formData.append('file', payload.file);
    formData.append('url', payload.url);

    const { data } = await this.httpClient.post(ApiServiceUriList.Kyc.UploadDocument, formData, {
      headers: {
        'Content-Type': 'multipart/form-Data'
      },
    });

    return data;

  }

  public async getParticipants(payload: IGetParticipantsRequest): Promise<IKycGetParticipantsResponse> {

    const { data } = await this.httpClient.post(ApiServiceUriList.Kyc.GetParticipants, payload);
    return data;

  }

  public async checkSignatures(payload: IGetParticipantsRequest): Promise<IKycGetParticipantsResponse> {

    const { data } = await this.httpClient.post(ApiServiceUriList.Kyc.CheckSignatures, payload);
    return data;

  }

  public async sendInvitation(payload: ISendInvitationRequest): Promise<IParticipant[]> {

    const { data } = await this.httpClient.post(ApiServiceUriList.Kyc.SendInvitation, payload);
    return data;

  }

  public async restartProcess(payload: IKycRestartProcess): Promise<void> {

    await this.httpClient.post(ApiServiceUriList.Kyc.Restart, payload);

  }

}

export const kycService = new KycService(httpClient);
