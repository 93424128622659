<template>
  <div>
    <h3 class="text-center text-dark-black">
      <b>Preguntas Frecuentes</b><br>
    </h3>
    <br>
    <div id="accordionExample" class="accordion">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="accordion-item mb-3 border-radius-15 bg-white shadow"
      >
        <a
          :id="`heading${index}`"
          :data-bs-target="`#collapse${index}`"
          :aria-controls="`collapse${index}`"
          data-bs-toggle="collapse"
          aria-expanded="true"
        >
          <p class="p-4 mb-0 text-dark-blue hover-transform-effect">
            <mdicon :name="!!item.icon?item.icon:'comment-question'" /> <b>{{ item.question }}</b>
          </p>
        </a>
        <div
          :id="`collapse${index}`"
          class="accordion-collapse collapse"
          :class="index===0?'show':''"
          :aria-labelledby="`heading${index}`"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body pt-0 text-dark">
            {{ item.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name  : 'FaqList',
  props : {
    shop: { type: Object, default: () => ({}), required: false }
  },
  data() {

    return {
      items: [
        {
          icon     : 'frequently-asked-questions',
          question : '¿Qué es el Portal de Cartolas RedPay?',
          answer   : 'El Portal de Cartolas o Portal de Cartolas RedPay, es una plataforma, diseñada para que los ' +
            'comercios puedan consultar en tiempo cercano al real sus movimientos de abonos y cargos ' +
            'en las distintas secciones que componen el Portal'
        },
        {
          icon     : 'frequently-asked-questions',
          question : '¿Qué puedo hacer en el Portal de Cartolas RedPay?',
          answer   : 'En el portal pueden revisar:\n' +
            'Tu saldo y movimientos de transacciones de devolución\n' +
            'Tu saldo y movimientos de transacciones de ventas y retiros de dinero ganado en ventas\n' +
            'Tu saldo y movimientos de residuos generados en el tiempo'
        },
        {
          icon     : 'frequently-asked-questions',
          question : '¿Qué son los residuos?',
          answer   : 'Los residuos son fracciones de peso que se generan en cada transacción a tu favor, ' +
            'considerando que el descuento que realizamos en línea en ocasiones en mayor que el ' +
            'cálculo exacto con decimales, esto genera que a fin de mes puedas “juntar” algunos pesos ' +
            'a tu favor. en la sección Residuos podrás visualizarlos'
        },
        {
          icon     : 'frequently-asked-questions',
          question : '¿Tiene costo para mí el uso de Portal de Cartolas RedPay?',
          answer   : 'Usar el portal no tiene costo alguno, si es importante mencionar que las transacciones que ' +
            'visualizas si tienen costos asociados. En el caso de las devoluciones estas tienen un costo de ' +
            '0,0022 UF + IVA por transacción realizada. En el caso de abono a cuentas bancarias fuera ' +
            'del ecosistema tienen un costo de 0,03 UF + IVA para destino Banco Estado y 0,02 UF + ' +
            'IVA en caso de otros bancos. Adicionalmente es importante mencionar que el alta para ' +
            'operar en módulo de abonos a otros bancos tiene un costo único de 0,244UF +IVA o 0,638 ' +
            'UF + IVA, si tienes una empresa en un día o empresa tradicional respectivamente.'
        },
        {
          icon     : 'frequently-asked-questions',
          question : '¿Debo inscribirme en el Portal de Cartolas RedPay?',
          answer   : 'El portal de cartolas está disponible para todos los comercios adheridos mediante un ' +
            'enrolador recaudador a la red de RedPay, por lo que solo debes crear tus credenciales, ' +
            'ahora bien en cada caso, devoluciones y abonos deberás realizar acciones adicionales que son ' +
            'requisitos de cada producto.'
        },
        {
          icon     : 'frequently-asked-questions',
          question : '¿Quién resguarda mi dinero?',
          answer   : 'Los saldos que visualizas en el portal de cartolas, en cada una de sus secciones, esta ' +
            'debidamente resguardada por el Banco Sponsor de RedPay, que es Banco BICE. Entidad ' +
            'CMF regulada.'
        },
        {
          icon     : 'frequently-asked-questions',
          question : '¿Cada cuanto tiempo o monto realizan un abono?',
          answer   : 'El tiempo o la periodicidad de cuando el portal de cartolas sección abonos distribuye dinero ' +
            'es parametrizable por tu enrolador recaudador, en el caso que no nos informe regla alguna, ' +
            'por defecto el portal revisa si cuentas con saldo suficiente, cada 24 horas hábiles, de ser ' +
            'positivo se realiza el abono a tu cuenta.\n' +
            'Se entiende por saldo suficiente cuando tu cuenta acumula un monto mayo o igual a 0,3 UFs'
        },
        {
          icon     : 'frequently-asked-questions',
          question : '¿Por qué es necesario el Portal de cartolas RedPay?',
          answer   : 'RedPay tiene el atributo de enviar dinero de las ventas en tiempo real, es decir, al momento ' +
            'de la compra, ahora bien para que esto ocurra, la cuenta del comercio receptor debe ser de ' +
            'una institución financiera integrada a RedPay, hoy no todas las instituciones están ' +
            'integradas, por lo que si tu cuenta bancaria pertenece a una institución financiera no ' +
            'integrada debemos usar este mecanismo para que puedas obtener el dinero de tus ventas. ' +
            'Estamos trabajando para unir a todas las instituciones financieras del país. Si quieres recibir ' +
            'el dinero de cuentas en línea, debes abrir una cuenta en una institución financiera integrada ' +
            'al ecosistema.'
        }
      ]
    };

  }
});
</script>
<style scoped>
.accordion-item{
  border: none;
  box-shadow: #222E3C;
}
.accordion-item a{
  text-decoration: none;
  width: 100% !important;
}

.hover-transform-effect{
  transition: transform .2s;
}

.hover-transform-effect:hover{
  transform: scale(1.005);
}
</style>

