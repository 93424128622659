export default [
  {
    'value' : '011101',
    'label' : 'CULTIVO DE TRIGO'
  },
  {
    'value' : '011102',
    'label' : 'CULTIVO DE MA\u00cdZ'
  },
  {
    'value' : '011103',
    'label' : 'CULTIVO DE AVENA'
  },
  {
    'value' : '011104',
    'label' : 'CULTIVO DE CEBADA'
  },
  {
    'value' : '011105',
    'label' : 'CULTIVO DE OTROS CEREALES (EXCEPTO TRIGO, MA\u00cdZ, AVENA Y CEBADA)'
  },
  {
    'value' : '011106',
    'label' : 'CULTIVO DE POROTOS'
  },
  {
    'value' : '011107',
    'label' : 'CULTIVO DE LUPINO'
  },
  {
    'value' : '011108',
    'label' : 'CULTIVO DE OTRAS LEGUMBRES (EXCEPTO POROTOS Y LUPINO)'
  },
  {
    'value' : '011109',
    'label' : 'CULTIVO DE SEMILLAS DE RAPS'
  },
  {
    'value' : '011110',
    'label' : 'CULTIVO DE SEMILLAS DE MARAVILLA (GIRASOL)'
  },
  {
    'value' : '011111',
    'label' : 'CULTIVO DE SEMILLAS DE CEREALES, LEGUMBRES Y OLEAGINOSAS (EXCEPTO SEMILLAS DE RAPS Y MARAVILLA)'
  },
  {
    'value' : '011200',
    'label' : 'CULTIVO DE ARROZ'
  },
  {
    'value' : '011301',
    'label' : 'CULTIVO DE PAPAS'
  },
  {
    'value' : '011302',
    'label' : 'CULTIVO DE CAMOTES'
  },
  {
    'value' : '011303',
    'label' : 'CULTIVO DE OTROS TUB\u00c9RCULOS (EXCEPTO PAPAS Y CAMOTES)'
  },
  {
    'value' : '011304',
    'label' : 'CULTIVO DE REMOLACHA AZUCARERA'
  },
  {
    'value' : '011305',
    'label' : 'CULTIVO DE SEMILLAS DE HORTALIZAS'
  },
  {
    'value' : '011306',
    'label' : 'CULTIVO DE HORTALIZAS Y MELONES'
  },
  {
    'value' : '011400',
    'label' : 'CULTIVO DE CA\u00d1A DE AZ\u00daCAR'
  },
  {
    'value' : '011500',
    'label' : 'CULTIVO DE TABACO'
  },
  {
    'value' : '011600',
    'label' : 'CULTIVO DE PLANTAS DE FIBRA'
  },
  {
    'value' : '011901',
    'label' : 'CULTIVO DE FLORES'
  },
  {
    'value' : '011902',
    'label' : 'CULTIVOS FORRAJEROS EN PRADERAS MEJORADAS O SEMBRADAS; CULTIVOS SUPLEMENTARIOS FORRAJEROS'
  },
  {
    'value' : '011903',
    'label' : 'CULTIVOS DE SEMILLAS DE FLORES; CULTIVO DE SEMILLAS DE PLANTAS FORRAJERAS'
  },
  {
    'value' : '012111',
    'label' : 'CULTIVO DE UVA DESTINADA A LA PRODUCCI\u00d3N DE PISCO Y AGUARDIENTE'
  },
  {
    'value' : '012112',
    'label' : 'CULTIVO DE UVA DESTINADA A LA PRODUCCI\u00d3N DE VINO'
  },
  {
    'value' : '012120',
    'label' : 'CULTIVO DE UVA PARA MESA'
  },
  {
    'value' : '012200',
    'label' : 'CULTIVO DE FRUTAS TROPICALES Y SUBTROPICALES (INCLUYE EL CULTIVO DE PALTAS)'
  },
  {
    'value' : '012300',
    'label' : 'CULTIVO DE C\u00cdTRICOS'
  },
  {
    'value' : '012400',
    'label' : 'CULTIVO DE FRUTAS DE PEPITA Y DE HUESO'
  },
  {
    'value' : '012501',
    'label' : 'CULTIVO DE SEMILLAS DE FRUTAS'
  },
  {
    'value' : '012502',
    'label' : 'CULTIVO DE OTROS FRUTOS Y NUECES DE \u00c1RBOLES Y ARBUSTOS'
  },
  {
    'value' : '012600',
    'label' : 'CULTIVO DE FRUTOS OLEAGINOSOS (INCLUYE EL CULTIVO DE ACEITUNAS)'
  },
  {
    'value' : '012700',
    'label' : 'CULTIVO DE PLANTAS CON LAS QUE SE PREPARAN BEBIDAS (INCLUYE EL CULTIVO DE CAF\u00c9, T\u00c9 Y MATE)'
  },
  {
    'value' : '012801',
    'label' : 'CULTIVO DE ESPECIAS'
  },
  {
    'value' : '012802',
    'label' : 'CULTIVO DE PLANTAS AROM\u00c1TICAS, MEDICINALES Y FARMAC\u00c9UTICAS'
  },
  {
    'value' : '012900',
    'label' : 'CULTIVO DE OTRAS PLANTAS PERENNES'
  },
  {
    'value' : '013000',
    'label' : 'CULTIVO DE PLANTAS VIVAS INCLUIDA LA PRODUCCI\u00d3N EN VIVEROS (EXCEPTO VIVEROS FORESTALES)'
  },
  {
    'value' : '014101',
    'label' : 'CR\u00cdA DE GANADO BOVINO PARA LA PRODUCCI\u00d3N LECHERA'
  },
  {
    'value' : '014102',
    'label' : 'CR\u00cdA DE GANADO BOVINO PARA LA PRODUCCI\u00d3N DE CARNE O COMO GANADO REPRODUCTOR'
  },
  {
    'value' : '014200',
    'label' : 'CR\u00cdA DE CABALLOS Y OTROS EQUINOS'
  },
  {
    'value' : '014300',
    'label' : 'CR\u00cdA DE LLAMAS, ALPACAS, VICU\u00d1AS, GUANACOS Y OTROS CAM\u00c9LIDOS'
  },
  {
    'value' : '014410',
    'label' : 'CR\u00cdA DE OVEJAS (OVINOS)'
  },
  {
    'value' : '014420',
    'label' : 'CR\u00cdA DE CABRAS (CAPRINOS)'
  },
  {
    'value' : '014500',
    'label' : 'CR\u00cdA DE CERDOS'
  },
  {
    'value' : '014601',
    'label' : 'CR\u00cdA DE AVES DE CORRAL PARA LA PRODUCCI\u00d3N DE CARNE'
  },
  {
    'value' : '014602',
    'label' : 'CR\u00cdA DE AVES DE CORRAL PARA LA PRODUCCI\u00d3N DE HUEVOS'
  },
  {
    'value' : '014901',
    'label' : 'APICULTURA'
  },
  {
    'value' : '014909',
    'label' : 'CR\u00cdA DE OTROS ANIMALES N.C.P.'
  },
  {
    'value' : '015000',
    'label' : 'CULTIVO DE PRODUCTOS AGR\u00cdCOLAS EN COMBINACI\u00d3N CON LA CR\u00cdA DE ANIMALES (EXPLOTACI\u00d3N MIXTA)'
  },
  {
    'value' : '016100',
    'label' : 'ACTIVIDADES DE APOYO A LA AGRICULTURA'
  },
  {
    'value' : '016200',
    'label' : 'ACTIVIDADES DE APOYO A LA GANADER\u00cdA'
  },
  {
    'value' : '016300',
    'label' : 'ACTIVIDADES POSCOSECHA'
  },
  {
    'value' : '016400',
    'label' : 'TRATAMIENTO DE SEMILLAS PARA PROPAGACI\u00d3N'
  },
  {
    'value' : '021001',
    'label' : 'EXPLOTACI\u00d3N DE VIVEROS FORESTALES'
  },
  {
    'value' : '021002',
    'label' : 'SILVICULTURA Y OTRAS ACTIVIDADES FORESTALES (EXCEPTO EXPLOTACI\u00d3N DE VIVEROS FORESTALES)'
  },
  {
    'value' : '022000',
    'label' : 'EXTRACCI\u00d3N DE MADERA'
  },
  {
    'value' : '023000',
    'label' : 'RECOLECCI\u00d3N DE PRODUCTOS FORESTALES DISTINTOS DE LA MADERA'
  },
  {
    'value' : '024001',
    'label' : 'SERVICIOS DE FORESTACI\u00d3N A CAMBIO DE UNA RETRIBUCI\u00d3N O POR CONTRATA'
  },
  {
    'value' : '024002',
    'label' : 'SERVICIOS DE CORTA DE MADERA A CAMBIO DE UNA RETRIBUCI\u00d3N O POR CONTRATA'
  },
  {
    'value' : '024003',
    'label' : 'SERVICIOS DE EXTINCI\u00d3N Y PREVENCI\u00d3N DE INCENDIOS FORESTALES'
  },
  {
    'value' : '024009',
    'label' : 'OTROS SERVICIOS DE APOYO A LA SILVICULTURA N.C.P.'
  },
  {
    'value' : '031110',
    'label' : 'PESCA MAR\u00cdTIMA INDUSTRIAL, EXCEPTO DE BARCOS FACTOR\u00cdA'
  },
  {
    'value' : '031120',
    'label' : 'PESCA MAR\u00cdTIMA ARTESANAL'
  },
  {
    'value' : '031130',
    'label' : 'RECOLECCI\u00d3N Y EXTRACCI\u00d3N DE PRODUCTOS MARINOS'
  },
  {
    'value' : '031140',
    'label' : 'SERVICIOS RELACIONADOS CON LA PESCA MAR\u00cdTIMA'
  },
  {
    'value' : '031200',
    'label' : 'PESCA DE AGUA DULCE'
  },
  {
    'value' : '032110',
    'label' : 'CULTIVO Y CRIANZA DE PECES MARINOS'
  },
  {
    'value' : '032120',
    'label' : 'CULTIVO, REPRODUCCI\u00d3N Y MANEJO DE ALGAS MARINAS'
  },
  {
    'value' : '032130',
    'label' : 'REPRODUCCI\u00d3N Y CR\u00cdA DE MOLUSCOS, CRUST\u00c1CEOS Y GUSANOS MARINOS'
  },
  {
    'value' : '032140',
    'label' : 'SERVICIOS RELACIONADOS CON LA ACUICULTURA MARINA'
  },
  {
    'value' : '032200',
    'label' : 'ACUICULTURA DE AGUA DULCE'
  },
  {
    'value' : '040000',
    'label' : 'EXTRACCI\u00d3N Y PROCESAMIENTO DE COBRE'
  },
  {
    'value' : '051000',
    'label' : 'EXTRACCI\u00d3N DE CARB\u00d3N DE PIEDRA'
  },
  {
    'value' : '052000',
    'label' : 'EXTRACCI\u00d3N DE LIGNITO'
  },
  {
    'value' : '061000',
    'label' : 'EXTRACCI\u00d3N DE PETR\u00d3LEO CRUDO'
  },
  {
    'value' : '062000',
    'label' : 'EXTRACCI\u00d3N DE GAS NATURAL'
  },
  {
    'value' : '071000',
    'label' : 'EXTRACCI\u00d3N DE MINERALES DE HIERRO'
  },
  {
    'value' : '072100',
    'label' : 'EXTRACCI\u00d3N DE MINERALES DE URANIO Y TORIO'
  },
  {
    'value' : '072910',
    'label' : 'EXTRACCI\u00d3N DE ORO Y PLATA'
  },
  {
    'value' : '072991',
    'label' : 'EXTRACCI\u00d3N DE ZINC Y PLOMO'
  },
  {
    'value' : '072992',
    'label' : 'EXTRACCI\u00d3N DE MANGANESO'
  },
  {
    'value' : '072999',
    'label' : 'EXTRACCI\u00d3N DE OTROS MINERALES METAL\u00cdFEROS NO FERROSOS N.C.P. (EXCEPTO ZINC, PLOMO Y MANGANESO)'
  },
  {
    'value' : '081000',
    'label' : 'EXTRACCI\u00d3N DE PIEDRA, ARENA Y ARCILLA'
  },
  {
    'value' : '089110',
    'label' : 'EXTRACCI\u00d3N Y PROCESAMIENTO DE LITIO'
  },
  {
    'value' : '089190',
    'label' : 'EXTRACCI\u00d3N DE MINERALES PARA LA FABRICACI\u00d3N DE ABONOS Y PRODUCTOS QU\u00cdMICOS N.C.P.'
  },
  {
    'value' : '089200',
    'label' : 'EXTRACCI\u00d3N DE TURBA'
  },
  {
    'value' : '089300',
    'label' : 'EXTRACCI\u00d3N DE SAL'
  },
  {
    'value' : '089900',
    'label' : 'EXPLOTACI\u00d3N DE OTRAS MINAS Y CANTERAS N.C.P.'
  },
  {
    'value' : '091001',
    'label' : 'ACTIVIDADES DE APOYO PARA LA EXTRACCI\u00d3N DE PETR\u00d3LEO Y GAS NATURAL PRESTADOS POR EMPRESAS'
  },
  {
    'value' : '091002',
    'label' : 'ACTIVIDADES DE APOYO PARA LA EXTRACCI\u00d3N DE PETR\u00d3LEO Y GAS NATURAL PRESTADOS POR PROFESIONALES'
  },
  {
    'value' : '099001',
    'label' : 'ACTIVIDADES DE APOYO PARA LA EXPLOTACI\u00d3N DE OTRAS MINAS Y CANTERAS PRESTADOS POR EMPRESAS'
  },
  {
    'value' : '099002',
    'label' : 'ACTIVIDADES DE APOYO PARA LA EXPLOTACI\u00d3N DE OTRAS MINAS Y CANTERAS PRESTADOS POR PROFESIONALES'
  },
  {
    'value' : '101011',
    'label' : 'EXPLOTACI\u00d3N DE MATADEROS DE BOVINOS, OVINOS, EQUINOS, CAPRINOS, PORCINOS Y CAM\u00c9LIDOS'
  },
  {
    'value' : '101019',
    'label' : 'EXPLOTACI\u00d3N DE MATADEROS DE AVES Y DE OTROS TIPOS DE ANIMALES N.C.P.'
  },
  {
    'value' : '101020',
    'label' : 'ELABORACI\u00d3N Y CONSERVACI\u00d3N DE CARNE Y PRODUCTOS C\u00c1RNICOS'
  },
  {
    'value' : '102010',
    'label' : 'PRODUCCI\u00d3N DE HARINA DE PESCADO'
  },
  {
    'value' : '102020',
    'label' : 'ELABORACI\u00d3N Y CONSERVACI\u00d3N DE SALM\u00d3NIDOS'
  },
  {
    'value' : '102030',
    'label' : 'ELABORACI\u00d3N Y CONSERVACI\u00d3N DE OTROS PESCADOS, EN PLANTAS EN TIERRA (EXCEPTO BARCOS FACTOR\u00cdA)'
  },
  {
    'value' : '102040',
    'label' : 'ELABORACI\u00d3N Y CONSERVACI\u00d3N DE CRUST\u00c1CEOS, MOLUSCOS Y OTROS PRODUCTOS ACU\u00c1TICOS, EN PLANTAS EN TIERRA'
  },
  {
    'value' : '102050',
    'label' : 'ACTIVIDADES DE ELABORACI\u00d3N Y CONSERVACI\u00d3N DE PESCADO, REALIZADAS EN BARCOS FACTOR\u00cdA'
  },
  {
    'value' : '102060',
    'label' : 'ELABORACI\u00d3N Y PROCESAMIENTO DE ALGAS'
  },
  {
    'value' : '103000',
    'label' : 'ELABORACI\u00d3N Y CONSERVACI\u00d3N DE FRUTAS, LEGUMBRES Y HORTALIZAS'
  },
  {
    'value' : '104000',
    'label' : 'ELABORACI\u00d3N DE ACEITES Y GRASAS DE ORIGEN VEGETAL Y ANIMAL (EXCEPTO ELABORACI\u00d3N DE MANTEQUILLA)'
  },
  {
    'value' : '105000',
    'label' : 'ELABORACI\u00d3N DE PRODUCTOS L\u00c1CTEOS'
  },
  {
    'value' : '106101',
    'label' : 'MOLIENDA DE TRIGO: PRODUCCI\u00d3N DE HARINA, S\u00c9MOLA Y GR\u00c1NULOS'
  },
  {
    'value' : '106102',
    'label' : 'MOLIENDA DE ARROZ; PRODUCCI\u00d3N DE HARINA DE ARROZ'
  },
  {
    'value' : '106109',
    'label' : 'ELABORACI\u00d3N DE OTROS PRODUCTOS DE MOLINER\u00cdA N.C.P.'
  },
  {
    'value' : '106200',
    'label' : 'ELABORACI\u00d3N DE ALMIDONES Y PRODUCTOS DERIVADOS DEL ALMID\u00d3N'
  },
  {
    'value' : '107100',
    'label' : 'ELABORACI\u00d3N DE PRODUCTOS DE PANADER\u00cdA Y PASTELER\u00cdA'
  },
  {
    'value' : '107200',
    'label' : 'ELABORACI\u00d3N DE AZ\u00daCAR'
  },
  {
    'value' : '107300',
    'label' : 'ELABORACI\u00d3N DE CACAO, CHOCOLATE Y DE PRODUCTOS DE CONFITER\u00cdA'
  },
  {
    'value' : '107400',
    'label' : 'ELABORACI\u00d3N DE MACARRONES, FIDEOS, ALCUZCUZ Y PRODUCTOS FARIN\u00c1CEOS SIMILARES'
  },
  {
    'value' : '107500',
    'label' : 'ELABORACI\u00d3N DE COMIDAS Y PLATOS PREPARADOS ENVASADOS, ROTULADOS Y CON INFORMACI\u00d3N NUTRICIONAL'
  },
  {
    'value' : '107901',
    'label' : 'ELABORACI\u00d3N DE T\u00c9, CAF\u00c9, MATE E INFUSIONES DE HIERBAS'
  },
  {
    'value' : '107902',
    'label' : 'ELABORACI\u00d3N DE LEVADURAS NATURALES O ARTIFICIALES'
  },
  {
    'value' : '107903',
    'label' : 'ELABORACI\u00d3N DE VINAGRES, MOSTAZAS, MAYONESAS Y CONDIMENTOS EN GENERAL'
  },
  {
    'value' : '107909',
    'label' : 'ELABORACI\u00d3N DE OTROS PRODUCTOS ALIMENTICIOS N.C.P.'
  },
  {
    'value' : '108000',
    'label' : 'ELABORACI\u00d3N DE PIENSOS PREPARADOS PARA ANIMALES'
  },
  {
    'value' : '110110',
    'label' : 'ELABORACI\u00d3N DE PISCO (INDUSTRIAS PISQUERAS)'
  },
  {
    'value' : '110120',
    'label' : 'DESTILACI\u00d3N, RECTIFICACI\u00d3N Y MEZCLAS DE BEBIDAS ALCOH\u00d3LICAS; EXCEPTO PISCO'
  },
  {
    'value' : '110200',
    'label' : 'ELABORACI\u00d3N DE VINOS'
  },
  {
    'value' : '110300',
    'label' : 'ELABORACI\u00d3N DE BEBIDAS MALTEADAS Y DE MALTA'
  },
  {
    'value' : '110401',
    'label' : 'ELABORACI\u00d3N DE BEBIDAS NO ALCOH\u00d3LICAS'
  },
  {
    'value' : '110402',
    'label' : 'PRODUCCI\u00d3N DE AGUAS MINERALES Y OTRAS AGUAS EMBOTELLADAS'
  },
  {
    'value' : '120001',
    'label' : 'ELABORACI\u00d3N DE CIGARROS Y CIGARRILLOS'
  },
  {
    'value' : '120009',
    'label' : 'ELABORACI\u00d3N DE OTROS PRODUCTOS DE TABACO N.C.P.'
  },
  {
    'value' : '131100',
    'label' : 'PREPARACI\u00d3N E HILATURA DE FIBRAS TEXTILES'
  },
  {
    'value' : '131200',
    'label' : 'TEJEDURA DE PRODUCTOS TEXTILES'
  },
  {
    'value' : '131300',
    'label' : 'ACABADO DE PRODUCTOS TEXTILES'
  },
  {
    'value' : '139200',
    'label' : 'FABRICACI\u00d3N DE ART\u00cdCULOS CONFECCIONADOS DE MATERIALES TEXTILES, EXCEPTO PRENDAS DE VESTIR'
  },
  {
    'value' : '139400',
    'label' : 'FABRICACI\u00d3N DE CUERDAS, CORDELES, BRAMANTES Y REDES'
  },
  {
    'value' : '139900',
    'label' : 'FABRICACI\u00d3N DE OTROS PRODUCTOS TEXTILES N.C.P.'
  },
  {
    'value' : '141004',
    'label' : 'FABRICACI\u00d3N DE ROPA DE TRABAJO'
  },
  {
    'value' : '161000',
    'label' : 'ASERRADO Y ACEPILLADURA DE MADERA'
  },
  {
    'value' : '162100',
    'label' : 'FABRICACI\u00d3N DE HOJAS DE MADERA PARA ENCHAPADO Y TABLEROS A BASE DE MADERA'
  },
  {
    'value' : '162200',
    'label' : 'FABRICACI\u00d3N DE PARTES Y PIEZAS DE CARPINTER\u00cdA PARA EDIFICIOS Y CONSTRUCCIONES'
  },
  {
    'value' : '162300',
    'label' : 'FABRICACI\u00d3N DE RECIPIENTES DE MADERA'
  },
  {
    'value' : '162900',
    'label' : 'FABRICACI\u00d3N DE OTROS PRODUCTOS DE MADERA, DE ART\u00cdCULOS DE CORCHO, PAJA Y MATERIALES TRENZABLES'
  },
  {
    'value' : '170110',
    'label' : 'FABRICACI\u00d3N DE CELULOSA Y OTRAS PASTAS DE MADERA'
  },
  {
    'value' : '170190',
    'label' : 'FABRICACI\u00d3N DE PAPEL Y CART\u00d3N PARA SU POSTERIOR USO INDUSTRIAL N.C.P.'
  },
  {
    'value' : '170200',
    'label' : 'FABRICACI\u00d3N DE PAPEL Y CART\u00d3N ONDULADO Y DE ENVASES DE PAPEL Y CART\u00d3N'
  },
  {
    'value' : '170900',
    'label' : 'FABRICACI\u00d3N DE OTROS ART\u00cdCULOS DE PAPEL Y CART\u00d3N'
  },
  {
    'value' : '181109',
    'label' : 'OTRAS ACTIVIDADES DE IMPRESI\u00d3N N.C.P.'
  },
  {
    'value' : '181200',
    'label' : 'ACTIVIDADES DE SERVICIOS RELACIONADAS CON LA IMPRESI\u00d3N'
  },
  {
    'value' : '191000',
    'label' : 'FABRICACI\u00d3N DE PRODUCTOS DE HORNOS DE COQUE'
  },
  {
    'value' : '192000',
    'label' : 'FABRICACI\u00d3N DE PRODUCTOS DE LA REFINACI\u00d3N DEL PETR\u00d3LEO'
  },
  {
    'value' : '201101',
    'label' : 'FABRICACI\u00d3N DE CARB\u00d3N VEGETAL (EXCEPTO ACTIVADO); FABRICACI\u00d3N DE BRIQUETAS DE CARB\u00d3N VEGETAL'
  },
  {
    'value' : '201109',
    'label' : 'FABRICACI\u00d3N DE OTRAS SUSTANCIAS QU\u00cdMICAS B\u00c1SICAS N.C.P.'
  },
  {
    'value' : '201200',
    'label' : 'FABRICACI\u00d3N DE ABONOS Y COMPUESTOS DE NITR\u00d3GENO'
  },
  {
    'value' : '201300',
    'label' : 'FABRICACI\u00d3N DE PL\u00c1STICOS Y CAUCHO SINT\u00c9TICO EN FORMAS PRIMARIAS'
  },
  {
    'value' : '202100',
    'label' : 'FABRICACI\u00d3N DE PLAGUICIDAS Y OTROS PRODUCTOS QU\u00cdMICOS DE USO AGROPECUARIO'
  },
  {
    'value' : '202300',
    'label' : 'FABRICACI\u00d3N DE JABONES Y DETERGENTES, PREPARADOS PARA LIMPIAR, PERFUMES Y PREPARADOS DE TOCADOR'
  },
  {
    'value' : '202901',
    'label' : 'FABRICACI\u00d3N DE EXPLOSIVOS Y PRODUCTOS PIROT\u00c9CNICOS'
  },
  {
    'value' : '202909',
    'label' : 'FABRICACI\u00d3N DE OTROS PRODUCTOS QU\u00cdMICOS N.C.P.'
  },
  {
    'value' : '210000',
    'label' : 'FABRICACI\u00d3N DE PRODUCTOS FARMAC\u00c9UTICOS, SUSTANCIAS QU\u00cdMICAS MEDICINALES Y PRODUCTOS BOT\u00c1NICOS'
  },
  {
    'value' : '221100',
    'label' : 'FABRICACI\u00d3N DE CUBIERTAS Y C\u00c1MARAS DE CAUCHO; RECAUCHUTADO Y RENOVACI\u00d3N DE CUBIERTAS DE CAUCHO'
  },
  {
    'value' : '221900',
    'label' : 'FABRICACI\u00d3N DE OTROS PRODUCTOS DE CAUCHO'
  },
  {
    'value' : '222000',
    'label' : 'FABRICACI\u00d3N DE PRODUCTOS DE PL\u00c1STICO'
  },
  {
    'value' : '231001',
    'label' : 'FABRICACI\u00d3N DE VIDRIO PLANO'
  },
  {
    'value' : '231002',
    'label' : 'FABRICACI\u00d3N DE VIDRIO HUECO'
  },
  {
    'value' : '231003',
    'label' : 'FABRICACI\u00d3N DE FIBRAS DE VIDRIO'
  },
  {
    'value' : '231009',
    'label' : 'FABRICACI\u00d3N DE PRODUCTOS DE VIDRIO N.C.P.'
  },
  {
    'value' : '239100',
    'label' : 'FABRICACI\u00d3N DE PRODUCTOS REFRACTARIOS'
  },
  {
    'value' : '239200',
    'label' : 'FABRICACI\u00d3N DE MATERIALES DE CONSTRUCCI\u00d3N DE ARCILLA'
  },
  {
    'value' : '239400',
    'label' : 'FABRICACI\u00d3N DE CEMENTO, CAL Y YESO'
  },
  {
    'value' : '239500',
    'label' : 'FABRICACI\u00d3N DE ART\u00cdCULOS DE HORMIG\u00d3N, CEMENTO Y YESO'
  },
  {
    'value' : '239900',
    'label' : 'FABRICACI\u00d3N DE OTROS PRODUCTOS MINERALES NO MET\u00c1LICOS N.C.P.'
  },
  {
    'value' : '241000',
    'label' : 'INDUSTRIAS B\u00c1SICAS DE HIERRO Y ACERO'
  },
  {
    'value' : '242001',
    'label' : 'FABRICACI\u00d3N DE PRODUCTOS PRIMARIOS DE COBRE'
  },
  {
    'value' : '242002',
    'label' : 'FABRICACI\u00d3N DE PRODUCTOS PRIMARIOS DE ALUMINIO'
  },
  {
    'value' : '242009',
    'label' : 'FABRICACI\u00d3N DE PRODUCTOS PRIMARIOS DE METALES PRECIOSOS Y DE OTROS METALES NO FERROSOS N.C.P.'
  },
  {
    'value' : '243100',
    'label' : 'FUNDICI\u00d3N DE HIERRO Y ACERO'
  },
  {
    'value' : '243200',
    'label' : 'FUNDICI\u00d3N DE METALES NO FERROSOS'
  },
  {
    'value' : '251100',
    'label' : 'FABRICACI\u00d3N DE PRODUCTOS MET\u00c1LICOS PARA USO ESTRUCTURAL'
  },
  {
    'value' : '251201',
    'label' : 'FABRICACI\u00d3N DE RECIPIENTES DE METAL PARA GASES COMPRIMIDOS O LICUADOS'
  },
  {
    'value' : '251209',
    'label' : 'FABRICACI\u00d3N DE TANQUES, DEP\u00d3SITOS Y RECIPIENTES DE METAL N.C.P.'
  },
  {
    'value' : '251300',
    'label' : 'FABRICACI\u00d3N DE GENERADORES DE VAPOR, EXCEPTO CALDERAS DE AGUA CALIENTE PARA CALEFACCI\u00d3N CENTRAL'
  },
  {
    'value' : '259100',
    'label' : 'FORJA, PRENSADO, ESTAMPADO Y LAMINADO DE METALES; PULVIMETALURGIA'
  },
  {
    'value' : '259200',
    'label' : 'TRATAMIENTO Y REVESTIMIENTO DE METALES; MAQUINADO'
  },
  {
    'value' : '259300',
    'label' : 'FABRICACI\u00d3N DE ART\u00cdCULOS DE CUCHILLER\u00cdA, HERRAMIENTAS DE MANO Y ART\u00cdCULOS DE FERRETER\u00cdA'
  },
  {
    'value' : '259900',
    'label' : 'FABRICACI\u00d3N DE OTROS PRODUCTOS ELABORADOS DE METAL N.C.P.'
  },
  {
    'value' : '261000',
    'label' : 'FABRICACI\u00d3N DE COMPONENTES Y TABLEROS ELECTR\u00d3NICOS'
  },
  {
    'value' : '262000',
    'label' : 'FABRICACI\u00d3N DE COMPUTADORES Y EQUIPO PERIF\u00c9RICO'
  },
  {
    'value' : '263000',
    'label' : 'FABRICACI\u00d3N DE EQUIPO DE COMUNICACIONES'
  },
  {
    'value' : '264000',
    'label' : 'FABRICACI\u00d3N DE APARATOS ELECTR\u00d3NICOS DE CONSUMO'
  },
  {
    'value' : '266000',
    'label' : 'FABRICACI\u00d3N DE EQUIPO DE IRRADIACI\u00d3N Y EQUIPO ELECTR\u00d3NICO DE USO M\u00c9DICO Y TERAP\u00c9UTICO'
  },
  {
    'value' : '267000',
    'label' : 'FABRICACI\u00d3N DE INSTRUMENTOS \u00d3PTICOS Y EQUIPO FOTOGR\u00c1FICO'
  },
  {
    'value' : '268000',
    'label' : 'FABRICACI\u00d3N DE SOPORTES MAGN\u00c9TICOS Y \u00d3PTICOS'
  },
  {
    'value' : '271000',
    'label' : 'FABRICACI\u00d3N DE MOTORES, GENERADORES Y TRANSFORMADORES EL\u00c9CTRICOS, APARATOS DE DISTRIBUCI\u00d3N Y CONTROL'
  },
  {
    'value' : '272000',
    'label' : 'FABRICACI\u00d3N DE PILAS, BATER\u00cdAS Y ACUMULADORES'
  },
  {
    'value' : '273100',
    'label' : 'FABRICACI\u00d3N DE CABLES DE FIBRA \u00d3PTICA'
  },
  {
    'value' : '273200',
    'label' : 'FABRICACI\u00d3N DE OTROS HILOS Y CABLES EL\u00c9CTRICOS'
  },
  {
    'value' : '273300',
    'label' : 'FABRICACI\u00d3N DE DISPOSITIVOS DE CABLEADO'
  },
  {
    'value' : '274000',
    'label' : 'FABRICACI\u00d3N DE EQUIPO EL\u00c9CTRICO DE ILUMINACI\u00d3N'
  },
  {
    'value' : '275000',
    'label' : 'FABRICACI\u00d3N DE APARATOS DE USO DOM\u00c9STICO'
  },
  {
    'value' : '279000',
    'label' : 'FABRICACI\u00d3N DE OTROS TIPOS DE EQUIPO EL\u00c9CTRICO'
  },
  {
    'value' : '281100',
    'label' : 'FABRICACI\u00d3N DE MOTORES Y TURBINAS, EXCEPTO PARA AERONAVES, VEH\u00cdCULOS AUTOMOTORES Y MOTOCICLETAS'
  },
  {
    'value' : '281200',
    'label' : 'FABRICACI\u00d3N DE EQUIPO DE PROPULSI\u00d3N DE FLUIDOS'
  },
  {
    'value' : '281300',
    'label' : 'FABRICACI\u00d3N DE OTRAS BOMBAS, COMPRESORES, GRIFOS Y V\u00c1LVULAS'
  },
  {
    'value' : '281400',
    'label' : 'FABRICACI\u00d3N DE COJINETES, ENGRANAJES, TRENES DE ENGRANAJES Y PIEZAS DE TRANSMISI\u00d3N'
  },
  {
    'value' : '281500',
    'label' : 'FABRICACI\u00d3N DE HORNOS, CALDERAS Y QUEMADORES'
  },
  {
    'value' : '281600',
    'label' : 'FABRICACI\u00d3N DE EQUIPO DE ELEVACI\u00d3N Y MANIPULACI\u00d3N'
  },
  {
    'value' : '282100',
    'label' : 'FABRICACI\u00d3N DE MAQUINARIA AGROPECUARIA Y FORESTAL'
  },
  {
    'value' : '282200',
    'label' : 'FABRICACI\u00d3N DE MAQUINARIA PARA LA CONFORMACI\u00d3N DE METALES Y DE M\u00c1QUINAS HERRAMIENTA'
  },
  {
    'value' : '282300',
    'label' : 'FABRICACI\u00d3N DE MAQUINARIA METAL\u00daRGICA'
  },
  {
    'value' : '282400',
    'label' : 'FABRICACI\u00d3N DE MAQUINARIA PARA LA EXPLOTACI\u00d3N DE MINAS Y CANTERAS Y PARA OBRAS DE CONSTRUCCI\u00d3N'
  },
  {
    'value' : '282500',
    'label' : 'FABRICACI\u00d3N DE MAQUINARIA PARA LA ELABORACI\u00d3N DE ALIMENTOS, BEBIDAS Y TABACO'
  },
  {
    'value' : '302000',
    'label' : 'FABRICACI\u00d3N DE LOCOMOTORAS Y MATERIAL RODANTE'
  },
  {
    'value' : '310001',
    'label' : 'FABRICACI\u00d3N DE MUEBLES PRINCIPALMENTE DE MADERA'
  },
  {
    'value' : '310009',
    'label' : 'FABRICACI\u00d3N DE COLCHONES; FABRICACI\u00d3N DE OTROS MUEBLES N.C.P.'
  },
  {
    'value' : '325001',
    'label' : 'ACTIVIDADES DE LABORATORIOS DENTALES'
  },
  {
    'value' : '325009',
    'label' : 'FABRICACI\u00d3N DE INSTRUMENTOS Y MATERIALES M\u00c9DICOS, OFTALMOL\u00d3GICOS Y ODONTOL\u00d3GICOS N.C.P.'
  },
  {
    'value' : '331100',
    'label' : 'REPARACI\u00d3N DE PRODUCTOS ELABORADOS DE METAL'
  },
  {
    'value' : '331201',
    'label' : 'REPARACI\u00d3N DE MAQUINARIA AGROPECUARIA Y FORESTAL'
  },
  {
    'value' : '331202',
    'label' : 'REPARACI\u00d3N DE MAQUINARIA METAL\u00daRGICA, PARA LA MINER\u00cdA, EXTRACCI\u00d3N DE PETR\u00d3LEO Y PARA LA CONSTRUCCI\u00d3N'
  },
  {
    'value' : '331203',
    'label' : 'REPARACI\u00d3N DE MAQUINARIA PARA LA ELABORACI\u00d3N DE ALIMENTOS, BEBIDAS Y TABACO'
  },
  {
    'value' : '331209',
    'label' : 'REPARACI\u00d3N DE OTRO TIPO DE MAQUINARIA Y EQUIPOS INDUSTRIALES N.C.P.'
  },
  {
    'value' : '331301',
    'label' : 'REPARACI\u00d3N DE EQUIPO DE MEDICI\u00d3N, PRUEBA, NAVEGACI\u00d3N Y CONTROL'
  },
  {
    'value' : '331309',
    'label' : 'REPARACI\u00d3N DE OTROS EQUIPOS ELECTR\u00d3NICOS Y \u00d3PTICOS N.C.P.'
  },
  {
    'value' : '331400',
    'label' : 'REPARACI\u00d3N DE EQUIPO EL\u00c9CTRICO (EXCEPTO REPARACI\u00d3N DE EQUIPO Y ENSERES DOM\u00c9STICOS)'
  },
  {
    'value' : '331501',
    'label' : 'REPARACI\u00d3N DE BUQUES, EMBARCACIONES MENORES Y ESTRUCTURAS FLOTANTES'
  },
  {
    'value' : '331502',
    'label' : 'REPARACI\u00d3N DE AERONAVES Y NAVES ESPACIALES'
  },
  {
    'value' : '331509',
    'label' : 'REPARACI\u00d3N DE OTROS EQUIPOS DE TRANSPORTE N.C.P., EXCEPTO VEH\u00cdCULOS AUTOMOTORES'
  },
  {
    'value' : '332000',
    'label' : 'INSTALACI\u00d3N DE MAQUINARIA Y EQUIPOS INDUSTRIALES'
  },
  {
    'value' : '351011',
    'label' : 'GENERACI\u00d3N DE ENERG\u00cdA EL\u00c9CTRICA EN CENTRALES HIDROEL\u00c9CTRICAS'
  },
  {
    'value' : '351012',
    'label' : 'GENERACI\u00d3N DE ENERG\u00cdA EL\u00c9CTRICA EN CENTRALES TERMOEL\u00c9CTRICAS'
  },
  {
    'value' : '351019',
    'label' : 'GENERACI\u00d3N DE ENERG\u00cdA EL\u00c9CTRICA EN OTRAS CENTRALES N.C.P.'
  },
  {
    'value' : '351020',
    'label' : 'TRANSMISI\u00d3N DE ENERG\u00cdA EL\u00c9CTRICA'
  },
  {
    'value' : '351030',
    'label' : 'DISTRIBUCI\u00d3N DE ENERG\u00cdA EL\u00c9CTRICA'
  },
  {
    'value' : '352010',
    'label' : 'REGASIFICACI\u00d3N DE GAS NATURAL LICUADO (GNL)'
  },
  {
    'value' : '352020',
    'label' : 'FABRICACI\u00d3N DE GAS; DISTRIBUCI\u00d3N DE COMBUSTIBLES GASEOSOS POR TUBER\u00cdA, EXCEPTO REGASIFICACI\u00d3N DE GNL'
  },
  {
    'value' : '353001',
    'label' : 'SUMINISTRO DE VAPOR Y DE AIRE ACONDICIONADO'
  },
  {
    'value' : '353002',
    'label' : 'ELABORACI\u00d3N DE HIELO (EXCEPTO FABRICACI\u00d3N DE HIELO SECO)'
  },
  {
    'value' : '360000',
    'label' : 'CAPTACI\u00d3N, TRATAMIENTO Y DISTRIBUCI\u00d3N DE AGUA'
  },
  {
    'value' : '370000',
    'label' : 'EVACUACI\u00d3N Y TRATAMIENTO DE AGUAS SERVIDAS'
  },
  {
    'value' : '381100',
    'label' : 'RECOGIDA DE DESECHOS NO PELIGROSOS'
  },
  {
    'value' : '381200',
    'label' : 'RECOGIDA DE DESECHOS PELIGROSOS'
  },
  {
    'value' : '382100',
    'label' : 'TRATAMIENTO Y ELIMINACI\u00d3N DE DESECHOS NO PELIGROSOS'
  },
  {
    'value' : '382200',
    'label' : 'TRATAMIENTO Y ELIMINACI\u00d3N DE DESECHOS PELIGROSOS'
  },
  {
    'value' : '383001',
    'label' : 'RECUPERACI\u00d3N Y RECICLAMIENTO DE DESPERDICIOS Y DESECHOS MET\u00c1LICOS'
  },
  {
    'value' : '383002',
    'label' : 'RECUPERACI\u00d3N Y RECICLAMIENTO DE PAPEL'
  },
  {
    'value' : '383003',
    'label' : 'RECUPERACI\u00d3N Y RECICLAMIENTO DE VIDRIO'
  },
  {
    'value' : '383009',
    'label' : 'RECUPERACI\u00d3N Y RECICLAMIENTO DE OTROS DESPERDICIOS Y DESECHOS N.C.P.'
  },
  {
    'value' : '410010',
    'label' : 'CONSTRUCCI\u00d3N DE EDIFICIOS PARA USO RESIDENCIAL'
  },
  {
    'value' : '410020',
    'label' : 'CONSTRUCCI\u00d3N DE EDIFICIOS PARA USO NO RESIDENCIAL'
  },
  {
    'value' : '421000',
    'label' : 'CONSTRUCCI\u00d3N DE CARRETERAS Y L\u00cdNEAS DE FERROCARRIL'
  },
  {
    'value' : '422000',
    'label' : 'CONSTRUCCI\u00d3N DE PROYECTOS DE SERVICIO P\u00daBLICO'
  },
  {
    'value' : '429000',
    'label' : 'CONSTRUCCI\u00d3N DE OTRAS OBRAS DE INGENIER\u00cdA CIVIL'
  },
  {
    'value' : '431100',
    'label' : 'DEMOLICI\u00d3N'
  },
  {
    'value' : '431200',
    'label' : 'PREPARACI\u00d3N DEL TERRENO'
  },
  {
    'value' : '432100',
    'label' : 'INSTALACIONES EL\u00c9CTRICAS'
  },
  {
    'value' : '432200',
    'label' : 'INSTALACIONES DE GASFITER\u00cdA, CALEFACCI\u00d3N Y AIRE ACONDICIONADO'
  },
  {
    'value' : '432900',
    'label' : 'OTRAS INSTALACIONES PARA OBRAS DE CONSTRUCCI\u00d3N'
  },
  {
    'value' : '452002',
    'label' : 'MANTENIMIENTO Y REPARACI\u00d3N DE VEH\u00cdCULOS AUTOMOTORES'
  },
  {
    'value' : '454003',
    'label' : 'MANTENIMIENTO Y REPARACI\u00d3N DE MOTOCICLETAS'
  },
  {
    'value' : '461001',
    'label' : 'CORRETAJE AL POR MAYOR DE PRODUCTOS AGR\u00cdCOLAS'
  },
  {
    'value' : '461002',
    'label' : 'CORRETAJE AL POR MAYOR DE GANADO'
  },
  {
    'value' : '462010',
    'label' : 'VENTA AL POR MAYOR DE MATERIAS PRIMAS AGR\u00cdCOLAS'
  },
  {
    'value' : '462020',
    'label' : 'VENTA AL POR MAYOR DE ANIMALES VIVOS'
  },
  {
    'value' : '462090',
    'label' : 'VENTA AL POR MAYOR DE OTRAS MATERIAS PRIMAS AGROPECUARIAS N.C.P.'
  },
  {
    'value' : '463011',
    'label' : 'VENTA AL POR MAYOR DE FRUTAS Y VERDURAS'
  },
  {
    'value' : '463012',
    'label' : 'VENTA AL POR MAYOR DE CARNE Y PRODUCTOS C\u00c1RNICOS'
  },
  {
    'value' : '463013',
    'label' : 'VENTA AL POR MAYOR DE PRODUCTOS DEL MAR (PESCADOS, MARISCOS Y ALGAS)'
  },
  {
    'value' : '463014',
    'label' : 'VENTA AL POR MAYOR DE PRODUCTOS DE CONFITER\u00cdA'
  },
  {
    'value' : '463019',
    'label' : 'VENTA AL POR MAYOR DE HUEVOS, L\u00c1CTEOS, ABARROTES Y DE OTROS ALIMENTOS N.C.P.'
  },
  {
    'value' : '463020',
    'label' : 'VENTA AL POR MAYOR DE BEBIDAS ALCOH\u00d3LICAS Y NO ALCOH\u00d3LICAS'
  },
  {
    'value' : '463030',
    'label' : 'VENTA AL POR MAYOR DE TABACO'
  },
  {
    'value' : '464902',
    'label' : 'VENTA AL POR MAYOR DE ART\u00cdCULOS EL\u00c9CTRICOS Y ELECTR\u00d3NICOS PARA EL HOGAR'
  },
  {
    'value' : '464903',
    'label' : 'VENTA AL POR MAYOR DE ART\u00cdCULOS DE PERFUMER\u00cdA, DE TOCADOR Y COSM\u00c9TICOS'
  },
  {
    'value' : '464904',
    'label' : 'VENTA AL POR MAYOR DE ART\u00cdCULOS DE PAPELER\u00cdA Y ESCRITORIO'
  },
  {
    'value' : '464906',
    'label' : 'VENTA AL POR MAYOR DE DIARIOS Y REVISTAS'
  },
  {
    'value' : '464907',
    'label' : 'VENTA AL POR MAYOR DE PRODUCTOS FARMAC\u00c9UTICOS Y MEDICINALES'
  },
  {
    'value' : '464908',
    'label' : 'VENTA AL POR MAYOR DE INSTRUMENTOS CIENT\u00cdFICOS Y QUIR\u00daRGICOS'
  },
  {
    'value' : '464909',
    'label' : 'VENTA AL POR MAYOR DE OTROS ENSERES DOM\u00c9STICOS N.C.P.'
  },
  {
    'value' : '465100',
    'label' : 'VENTA AL POR MAYOR DE COMPUTADORES, EQUIPO PERIF\u00c9RICO Y PROGRAMAS INFORM\u00c1TICOS'
  },
  {
    'value' : '465200',
    'label' : 'VENTA AL POR MAYOR DE EQUIPO, PARTES Y PIEZAS ELECTR\u00d3NICOS Y DE TELECOMUNICACIONES'
  },
  {
    'value' : '465300',
    'label' : 'VENTA AL POR MAYOR DE MAQUINARIA, EQUIPO Y MATERIALES AGROPECUARIOS'
  },
  {
    'value' : '465901',
    'label' : 'VENTA AL POR MAYOR DE MAQUINARIA METAL\u00daRGICA, PARA LA MINER\u00cdA, EXTRACCI\u00d3N DE PETR\u00d3LEO Y CONSTRUCCI\u00d3N'
  },
  {
    'value' : '465902',
    'label' : 'VENTA AL POR MAYOR DE MAQUINARIA PARA LA ELABORACI\u00d3N DE ALIMENTOS, BEBIDAS Y TABACO'
  },
  {
    'value' : '465905',
    'label' : 'VENTA AL POR MAYOR DE EQUIPO DE TRANSPORTE(EXCEPTO VEH\u00cdCULOS AUTOMOTORES, MOTOCICLETAS Y BICICLETAS)'
  },
  {
    'value' : '466100',
    'label' : 'VENTA AL POR MAYOR DE COMBUSTIBLES S\u00d3LIDOS, L\u00cdQUIDOS Y GASEOSOS Y PRODUCTOS CONEXOS'
  },
  {
    'value' : '466200',
    'label' : 'VENTA AL POR MAYOR DE METALES Y MINERALES METAL\u00cdFEROS'
  },
  {
    'value' : '466301',
    'label' : 'VENTA AL POR MAYOR DE MADERA EN BRUTO Y PRODUCTOS PRIMARIOS DE LA ELABORACI\u00d3N DE MADERA'
  },
  {
    'value' : '466302',
    'label' : 'VENTA AL POR MAYOR DE MATERIALES DE CONSTRUCCI\u00d3N, ART\u00cdCULOS DE FERRETER\u00cdA, GASFITER\u00cdA Y CALEFACCI\u00d3N'
  },
  {
    'value' : '466901',
    'label' : 'VENTA AL POR MAYOR DE PRODUCTOS QU\u00cdMICOS'
  },
  {
    'value' : '471100',
    'label' : 'VENTA AL POR MENOR EN COMERCIOS DE ALIMENTOS, BEBIDAS O TABACO (SUPERMERCADOS E HIPERMERCADOS)'
  },
  {
    'value' : '472101',
    'label' : 'VENTA AL POR MENOR DE ALIMENTOS EN COMERCIOS ESPECIALIZADOS (ALMACENES PEQUE\u00d1OS Y MINIMARKET)'
  },
  {
    'value' : '472102',
    'label' : 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE CARNE Y PRODUCTOS C\u00c1RNICOS'
  },
  {
    'value' : '472103',
    'label' : 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE FRUTAS Y VERDURAS (VERDULER\u00cdAS)'
  },
  {
    'value' : '472104',
    'label' : 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE PESCADO, MARISCOS Y PRODUCTOS CONEXOS'
  },
  {
    'value' : '472105',
    'label' : 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE PRODUCTOS DE PANADER\u00cdA Y PASTELER\u00cdA'
  },
  {
    'value' : '472109',
    'label' : 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE HUEVOS, CONFITES Y PRODUCTOS ALIMENTICIOS N.C.P.'
  },
  {
    'value' : '473000',
    'label' : 'VENTA AL POR MENOR DE COMBUSTIBLES PARA VEH\u00cdCULOS AUTOMOTORES EN COMERCIOS ESPECIALIZADOS'
  },
  {
    'value' : '474100',
    'label' : 'VENTA AL POR MENOR DE COMPUTADORES, EQUIPO PERIF\u00c9RICO, PROGRAMAS INFORM\u00c1TICOS Y EQUIPO DE TELECOM.'
  },
  {
    'value' : '475201',
    'label' : 'VENTA AL POR MENOR DE ART\u00cdCULOS DE FERRETER\u00cdA Y MATERIALES DE CONSTRUCCI\u00d3N'
  },
  {
    'value' : '475909',
    'label' : 'VENTA AL POR MENOR DE APARATOS EL\u00c9CTRICOS, TEXTILES PARA EL HOGAR Y OTROS ENSERES DOM\u00c9STICOS N.C.P.'
  },
  {
    'value' : '476102',
    'label' : 'VENTA AL POR MENOR DE DIARIOS Y REVISTAS EN COMERCIOS ESPECIALIZADOS'
  },
  {
    'value' : '477201',
    'label' : 'VENTA AL POR MENOR DE PRODUCTOS FARMAC\u00c9UTICOS Y MEDICINALES EN COMERCIOS ESPECIALIZADOS'
  },
  {
    'value' : '477202',
    'label' : 'VENTA AL POR MENOR DE ART\u00cdCULOS ORTOP\u00c9DICOS EN COMERCIOS ESPECIALIZADOS'
  },
  {
    'value' : '477203',
    'label' : 'VENTA AL POR MENOR DE ART\u00cdCULOS DE PERFUMER\u00cdA, DE TOCADOR Y COSM\u00c9TICOS EN COMERCIOS ESPECIALIZADOS'
  },
  {
    'value' : '477310',
    'label' : 'VENTA AL POR MENOR DE GAS LICUADO EN BOMBONAS (CILINDROS) EN COMERCIOS ESPECIALIZADOS'
  },
  {
    'value' : '477391',
    'label' : 'VENTA AL POR MENOR DE ALIMENTO Y ACCESORIOS PARA MASCOTAS EN COMERCIOS ESPECIALIZADOS'
  },
  {
    'value' : '477393',
    'label' : 'VENTA AL POR MENOR DE ART\u00cdCULOS \u00d3PTICOS EN COMERCIOS ESPECIALIZADOS'
  },
  {
    'value' : '477395',
    'label' : 'VENTA AL POR MENOR DE CARB\u00d3N, LE\u00d1A Y OTROS COMBUSTIBLES DE USO DOM\u00c9STICO EN COMERCIOS ESPECIALIZADOS'
  },
  {
    'value' : '477397',
    'label' : 'VENTA AL POR MENOR DE FLORES, PLANTAS, ARBOLES, SEMILLAS Y ABONOS EN COMERCIOS ESPECIALIZADOS'
  },
  {
    'value' : '478100',
    'label' : 'VENTA AL POR MENOR DE ALIMENTOS, BEBIDAS Y TABACO EN PUESTOS DE VENTA Y MERCADOS (INCLUYE FERIAS)'
  },
  {
    'value' : '478900',
    'label' : 'VENTA AL POR MENOR DE OTROS PRODUCTOS EN PUESTOS DE VENTA Y MERCADOS (INCLUYE FERIAS)'
  },
  {
    'value' : '479100',
    'label' : 'VENTA AL POR MENOR POR CORREO, POR INTERNET Y V\u00cdA TELEF\u00d3NICA'
  },
  {
    'value' : '491100',
    'label' : 'TRANSPORTE INTERURBANO DE PASAJEROS POR FERROCARRIL'
  },
  {
    'value' : '491200',
    'label' : 'TRANSPORTE DE CARGA POR FERROCARRIL'
  },
  {
    'value' : '492110',
    'label' : 'TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS V\u00cdA METRO Y METROTREN'
  },
  {
    'value' : '492120',
    'label' : 'TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS V\u00cdA LOCOMOCI\u00d3N COLECTIVA'
  },
  {
    'value' : '492130',
    'label' : 'TRANSPORTE DE PASAJEROS V\u00cdA TAXI COLECTIVO'
  },
  {
    'value' : '492190',
    'label' : 'OTRAS ACTIVIDADES DE TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS POR V\u00cdA TERRESTRE N.C.P.'
  },
  {
    'value' : '492220',
    'label' : 'SERVICIOS DE TRANSPORTE DE TRABAJADORES'
  },
  {
    'value' : '492230',
    'label' : 'SERVICIOS DE TRANSPORTE DE PASAJEROS EN TAXIS LIBRES Y RADIOTAXIS'
  },
  {
    'value' : '492250',
    'label' : 'TRANSPORTE DE PASAJEROS EN BUSES INTERURBANOS'
  },
  {
    'value' : '492290',
    'label' : 'OTRAS ACTIVIDADES DE TRANSPORTE DE PASAJEROS POR V\u00cdA TERRESTRE N.C.P.'
  },
  {
    'value' : '492300',
    'label' : 'TRANSPORTE DE CARGA POR CARRETERA'
  },
  {
    'value' : '493010',
    'label' : 'TRANSPORTE POR OLEODUCTOS'
  },
  {
    'value' : '493020',
    'label' : 'TRANSPORTE POR GASODUCTOS'
  },
  {
    'value' : '493090',
    'label' : 'OTRAS ACTIVIDADES DE TRANSPORTE POR TUBER\u00cdAS N.C.P.'
  },
  {
    'value' : '501100',
    'label' : 'TRANSPORTE DE PASAJEROS MAR\u00cdTIMO Y DE CABOTAJE'
  },
  {
    'value' : '501200',
    'label' : 'TRANSPORTE DE CARGA MAR\u00cdTIMO Y DE CABOTAJE'
  },
  {
    'value' : '502100',
    'label' : 'TRANSPORTE DE PASAJEROS POR V\u00cdAS DE NAVEGACI\u00d3N INTERIORES'
  },
  {
    'value' : '502200',
    'label' : 'TRANSPORTE DE CARGA POR V\u00cdAS DE NAVEGACI\u00d3N INTERIORES'
  },
  {
    'value' : '511000',
    'label' : 'TRANSPORTE DE PASAJEROS POR V\u00cdA A\u00c9REA'
  },
  {
    'value' : '512000',
    'label' : 'TRANSPORTE DE CARGA POR V\u00cdA A\u00c9REA'
  },
  {
    'value' : '521001',
    'label' : 'EXPLOTACI\u00d3N DE FRIGOR\u00cdFICOS PARA ALMACENAMIENTO Y DEP\u00d3SITO'
  },
  {
    'value' : '521009',
    'label' : 'OTROS SERVICIOS DE ALMACENAMIENTO Y DEP\u00d3SITO N.C.P.'
  },
  {
    'value' : '522110',
    'label' : 'EXPLOTACI\u00d3N DE TERMINALES TERRESTRES DE PASAJEROS'
  },
  {
    'value' : '522130',
    'label' : 'SERVICIOS PRESTADOS POR CONCESIONARIOS DE CARRETERAS'
  },
  {
    'value' : '522190',
    'label' : 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE TERRESTRE N.C.P.'
  },
  {
    'value' : '522200',
    'label' : 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE ACU\u00c1TICO'
  },
  {
    'value' : '522300',
    'label' : 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE A\u00c9REO'
  },
  {
    'value' : '522400',
    'label' : 'MANIPULACI\u00d3N DE LA CARGA'
  },
  {
    'value' : '522910',
    'label' : 'AGENCIAS DE ADUANAS'
  },
  {
    'value' : '522920',
    'label' : 'AGENCIAS DE NAVES'
  },
  {
    'value' : '522990',
    'label' : 'OTRAS ACTIVIDADES DE APOYO AL TRANSPORTE N.C.P.'
  },
  {
    'value' : '531000',
    'label' : 'ACTIVIDADES POSTALES'
  },
  {
    'value' : '532000',
    'label' : 'ACTIVIDADES DE MENSAJER\u00cdA'
  },
  {
    'value' : '551001',
    'label' : 'ACTIVIDADES DE HOTELES'
  },
  {
    'value' : '551003',
    'label' : 'ACTIVIDADES DE RESIDENCIALES PARA TURISTAS'
  },
  {
    'value' : '559001',
    'label' : 'ACTIVIDADES DE RESIDENCIALES PARA ESTUDIANTES Y TRABAJADORES'
  },
  {
    'value' : '559009',
    'label' : 'OTRAS ACTIVIDADES DE ALOJAMIENTO N.C.P.'
  },
  {
    'value' : '561000',
    'label' : 'ACTIVIDADES DE RESTAURANTES Y DE SERVICIO M\u00d3VIL DE COMIDAS'
  },
  {
    'value' : '562100',
    'label' : 'SUMINISTRO DE COMIDAS POR ENCARGO (SERVICIOS DE BANQUETER\u00cdA)'
  },
  {
    'value' : '562900',
    'label' : 'SUMINISTRO INDUSTRIAL DE COMIDAS POR ENCARGO; CONCESI\u00d3N DE SERVICIOS DE ALIMENTACI\u00d3N'
  },
  {
    'value' : '563009',
    'label' : 'OTRAS ACTIVIDADES DE SERVICIO DE BEBIDAS N.C.P.'
  },
  {
    'value' : '581300',
    'label' : 'EDICI\u00d3N DE DIARIOS, REVISTAS Y OTRAS PUBLICACIONES PERI\u00d3DICAS'
  },
  {
    'value' : '591100',
    'label' : 'ACTIVIDADES DE PRODUCCI\u00d3N DE PEL\u00cdCULAS CINEMATOGR\u00c1FICAS, VIDEOS Y PROGRAMAS DE TELEVISI\u00d3N'
  },
  {
    'value' : '591200',
    'label' : 'ACTIVIDADES DE POSTPRODUCCI\u00d3N DE PEL\u00cdCULAS CINEMATOGR\u00c1FICAS, VIDEOS Y PROGRAMAS DE TELEVISI\u00d3N'
  },
  {
    'value' : '591300',
    'label' : 'ACTIVIDADES DE DISTRIBUCI\u00d3N DE PEL\u00cdCULAS CINEMATOGR\u00c1FICAS, VIDEOS Y PROGRAMAS DE TELEVISI\u00d3N'
  },
  {
    'value' : '601000',
    'label' : 'TRANSMISIONES DE RADIO'
  },
  {
    'value' : '602000',
    'label' : 'PROGRAMACI\u00d3N Y TRANSMISIONES DE TELEVISI\u00d3N'
  },
  {
    'value' : '611010',
    'label' : 'TELEFON\u00cdA FIJA'
  },
  {
    'value' : '611020',
    'label' : 'TELEFON\u00cdA LARGA DISTANCIA'
  },
  {
    'value' : '611030',
    'label' : 'TELEVISI\u00d3N DE PAGO POR CABLE'
  },
  {
    'value' : '611090',
    'label' : 'OTROS SERVICIOS DE TELECOMUNICACIONES AL\u00c1MBRICAS N.C.P.'
  },
  {
    'value' : '612010',
    'label' : 'TELEFON\u00cdA M\u00d3VIL CELULAR'
  },
  {
    'value' : '612020',
    'label' : 'RADIOCOMUNICACIONES M\u00d3VILES'
  },
  {
    'value' : '612030',
    'label' : 'TELEVISI\u00d3N DE PAGO INAL\u00c1MBRICA'
  },
  {
    'value' : '612090',
    'label' : 'OTROS SERVICIOS DE TELECOMUNICACIONES INAL\u00c1MBRICAS N.C.P.'
  },
  {
    'value' : '613010',
    'label' : 'TELEFON\u00cdA M\u00d3VIL SATELITAL'
  },
  {
    'value' : '613020',
    'label' : 'TELEVISI\u00d3N DE PAGO SATELITAL'
  },
  {
    'value' : '613090',
    'label' : 'OTROS SERVICIOS DE TELECOMUNICACIONES POR SAT\u00c9LITE N.C.P.'
  },
  {
    'value' : '619090',
    'label' : 'OTRAS ACTIVIDADES DE TELECOMUNICACIONES N.C.P.'
  },
  {
    'value' : '620100',
    'label' : 'ACTIVIDADES DE PROGRAMACI\u00d3N INFORM\u00c1TICA'
  },
  {
    'value' : '620200',
    'label' : 'ACTIVIDADES DE CONSULTOR\u00cdA DE INFORM\u00c1TICA Y DE GESTI\u00d3N DE INSTALACIONES INFORM\u00c1TICAS'
  },
  {
    'value' : '620900',
    'label' : 'OTRAS ACTIVIDADES DE TECNOLOG\u00cdA DE LA INFORMACI\u00d3N Y DE SERVICIOS INFORM\u00c1TICOS'
  },
  {
    'value' : '631100',
    'label' : 'PROCESAMIENTO DE DATOS, HOSPEDAJE Y ACTIVIDADES CONEXAS'
  },
  {
    'value' : '631200',
    'label' : 'PORTALES WEB'
  },
  {
    'value' : '639100',
    'label' : 'ACTIVIDADES DE AGENCIAS DE NOTICIAS'
  },
  {
    'value' : '639900',
    'label' : 'OTRAS ACTIVIDADES DE SERVICIOS DE INFORMACI\u00d3N N.C.P.'
  },
  {
    'value' : '641100',
    'label' : 'BANCA CENTRAL'
  },
  {
    'value' : '641910',
    'label' : 'ACTIVIDADES BANCARIAS'
  },
  {
    'value' : '641990',
    'label' : 'OTROS TIPOS DE INTERMEDIACI\u00d3N MONETARIA N.C.P.'
  },
  {
    'value' : '642000',
    'label' : 'ACTIVIDADES DE SOCIEDADES DE CARTERA'
  },
  {
    'value' : '643000',
    'label' : 'FONDOS Y SOCIEDADES DE INVERSI\u00d3N Y ENTIDADES FINANCIERAS SIMILARES'
  },
  {
    'value' : '649100',
    'label' : 'LEASING FINANCIERO'
  },
  {
    'value' : '649201',
    'label' : 'FINANCIERAS'
  },
  {
    'value' : '649202',
    'label' : 'ACTIVIDADES DE CR\u00c9DITO PRENDARIO'
  },
  {
    'value' : '649203',
    'label' : 'CAJAS DE COMPENSACI\u00d3N'
  },
  {
    'value' : '649209',
    'label' : 'OTRAS ACTIVIDADES DE CONCESI\u00d3N DE CR\u00c9DITO N.C.P.'
  },
  {
    'value' : '649900',
    'label' : 'OTRAS ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES N.C.P.'
  },
  {
    'value' : '651100',
    'label' : 'SEGUROS DE VIDA'
  },
  {
    'value' : '651210',
    'label' : 'SEGUROS GENERALES, EXCEPTO ACTIVIDADES DE ISAPRES'
  },
  {
    'value' : '651220',
    'label' : 'ACTIVIDADES DE ISAPRES'
  },
  {
    'value' : '652000',
    'label' : 'REASEGUROS'
  },
  {
    'value' : '653000',
    'label' : 'FONDOS DE PENSIONES'
  },
  {
    'value' : '661100',
    'label' : 'ADMINISTRACI\u00d3N DE MERCADOS FINANCIEROS'
  },
  {
    'value' : '661201',
    'label' : 'ACTIVIDADES DE SECURITIZADORAS'
  },
  {
    'value' : '661202',
    'label' : 'CORREDORES DE BOLSA'
  },
  {
    'value' : '661203',
    'label' : 'AGENTES DE VALORES'
  },
  {
    'value' : '661204',
    'label' : 'ACTIVIDADES DE CASAS DE CAMBIO Y OPERADORES DE DIVISA'
  },
  {
    'value' : '661209',
    'label' : 'OTROS SERVICIOS DE CORRETAJE DE VALORES Y COMMODITIES N.C.P.'
  },
  {
    'value' : '661901',
    'label' : 'ACTIVIDADES DE C\u00c1MARAS DE COMPENSACI\u00d3N'
  },
  {
    'value' : '661902',
    'label' : 'ADMINISTRACI\u00d3N DE TARJETAS DE CR\u00c9DITO'
  },
  {
    'value' : '661904',
    'label' : 'ACTIVIDADES DE CLASIFICADORAS DE RIESGO'
  },
  {
    'value' : '661909',
    'label' : 'OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SERVICIOS FINANCIEROS N.C.P.'
  },
  {
    'value' : '662100',
    'label' : 'EVALUACI\u00d3N DE RIESGOS Y DA\u00d1OS (INCLUYE ACTIVIDADES DE LIQUIDADORES DE SEGUROS)'
  },
  {
    'value' : '662200',
    'label' : 'ACTIVIDADES DE AGENTES Y CORREDORES DE SEGUROS'
  },
  {
    'value' : '662900',
    'label' : 'OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SEGUROS Y FONDOS DE PENSIONES'
  },
  {
    'value' : '663010',
    'label' : 'ADMINISTRADORAS DE FONDOS DE PENSIONES (AFP)'
  },
  {
    'value' : '663091',
    'label' : 'ADMINISTRADORAS DE FONDOS DE INVERSI\u00d3N'
  },
  {
    'value' : '663092',
    'label' : 'ADMINISTRADORAS DE FONDOS MUTUOS'
  },
  {
    'value' : '663093',
    'label' : 'ADMINISTRADORAS DE FICES (FONDOS DE INVERSI\u00d3N DE CAPITAL EXTRANJERO)'
  },
  {
    'value' : '663094',
    'label' : 'ADMINISTRADORAS DE FONDOS PARA LA VIVIENDA'
  },
  {
    'value' : '663099',
    'label' : 'ADMINISTRADORAS DE FONDOS PARA OTROS FINES N.C.P.'
  },
  {
    'value' : '681011',
    'label' : 'ALQUILER DE BIENES INMUEBLES AMOBLADOS O CON EQUIPOS Y MAQUINARIAS'
  },
  {
    'value' : '691001',
    'label' : 'SERVICIOS DE ASESORAMIENTO Y REPRESENTACI\u00d3N JUR\u00cdDICA'
  },
  {
    'value' : '691002',
    'label' : 'SERVICIO NOTARIAL'
  },
  {
    'value' : '691003',
    'label' : 'CONSERVADOR DE BIENES RA\u00cdCES'
  },
  {
    'value' : '691004',
    'label' : 'RECEPTORES JUDICIALES'
  },
  {
    'value' : '691009',
    'label' : 'SERVICIOS DE ARBITRAJE; S\u00cdNDICOS DE QUIEBRA Y PERITOS JUDICIALES; OTRAS ACTIVIDADES JUR\u00cdDICAS N.C.P.'
  },
  {
    'value' : '692000',
    'label' : 'ACTIVIDADES DE CONTABILIDAD, TENEDUR\u00cdA DE LIBROS Y AUDITOR\u00cdA; CONSULTOR\u00cdA FISCAL'
  },
  {
    'value' : '712001',
    'label' : 'ACTIVIDADES DE PLANTAS DE REVISI\u00d3N T\u00c9CNICA PARA VEH\u00cdCULOS AUTOMOTORES'
  },
  {
    'value' : '721000',
    'label' : 'INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS NATURALES Y LA INGENIER\u00cdA'
  },
  {
    'value' : '722000',
    'label' : 'INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS SOCIALES Y LAS HUMANIDADES'
  },
  {
    'value' : '749003',
    'label' : 'SERVICIOS PERSONALES DE TRADUCCI\u00d3N E INTERPRETACI\u00d3N'
  },
  {
    'value' : '750001',
    'label' : 'ACTIVIDADES DE CL\u00cdNICAS VETERINARIAS'
  },
  {
    'value' : '750002',
    'label' : 'ACTIVIDADES DE VETERINARIOS, T\u00c9CNICOS Y OTRO PERSONAL AUXILIAR, PRESTADOS DE FORMA INDEPENDIENTE'
  },
  {
    'value' : '773001',
    'label' : 'ALQUILER DE EQUIPOS DE TRANSPORTE SIN OPERARIO, EXCEPTO VEH\u00cdCULOS AUTOMOTORES'
  },
  {
    'value' : '773002',
    'label' : 'ALQUILER DE MAQUINARIA Y EQUIPO AGROPECUARIO, FORESTAL, DE CONSTRUCCI\u00d3N E ING. CIVIL, SIN OPERARIOS'
  },
  {
    'value' : '801001',
    'label' : 'SERVICIOS DE SEGURIDAD PRIVADA PRESTADOS POR EMPRESAS'
  },
  {
    'value' : '801002',
    'label' : 'SERVICIO DE TRANSPORTE DE VALORES EN VEH\u00cdCULOS BLINDADOS'
  },
  {
    'value' : '801003',
    'label' : 'SERVICIOS DE SEGURIDAD PRIVADA PRESTADOS POR INDEPENDIENTES'
  },
  {
    'value' : '802000',
    'label' : 'ACTIVIDADES DE SERVICIOS DE SISTEMAS DE SEGURIDAD (INCLUYE SERVICIOS DE CERRAJER\u00cdA)'
  },
  {
    'value' : '812100',
    'label' : 'LIMPIEZA GENERAL DE EDIFICIOS'
  },
  {
    'value' : '812901',
    'label' : 'DESRATIZACI\u00d3N, DESINFECCI\u00d3N Y EXTERMINIO DE PLAGAS NO AGR\u00cdCOLAS'
  },
  {
    'value' : '812909',
    'label' : 'OTRAS ACTIVIDADES DE LIMPIEZA DE EDIFICIOS E INSTALACIONES INDUSTRIALES N.C.P.'
  },
  {
    'value' : '822000',
    'label' : 'ACTIVIDADES DE CALL-CENTER'
  },
  {
    'value' : '829120',
    'label' : 'ACTIVIDADES DE AGENCIAS DE CALIFICACI\u00d3N CREDITICIA'
  },
  {
    'value' : '829200',
    'label' : 'ACTIVIDADES DE ENVASADO Y EMPAQUETADO'
  },
  {
    'value' : '829900',
    'label' : 'OTRAS ACTIVIDADES DE SERVICIOS DE APOYO A LAS EMPRESAS N.C.P.'
  },
  {
    'value' : '841100',
    'label' : 'ACTIVIDADES DE LA ADMINISTRACI\u00d3N P\u00daBLICA EN GENERAL'
  },
  {
    'value' : '841200',
    'label' : 'REGULACI\u00d3N DE LAS ACTIVIDADES DE ORGANISMOS QUE PRESTAN SERVICIOS SANITARIOS, EDUCATIVOS, CULTURALES'
  },
  {
    'value' : '842100',
    'label' : 'RELACIONES EXTERIORES'
  },
  {
    'value' : '842200',
    'label' : 'ACTIVIDADES DE DEFENSA'
  },
  {
    'value' : '842300',
    'label' : 'ACTIVIDADES DE MANTENIMIENTO DEL ORDEN P\u00daBLICO Y DE SEGURIDAD'
  },
  {
    'value' : '843010',
    'label' : 'FONDO NACIONAL DE SALUD (FONASA)'
  },
  {
    'value' : '843020',
    'label' : 'INSTITUTO DE PREVISI\u00d3N SOCIAL (IPS)'
  },
  {
    'value' : '843090',
    'label' : 'OTROS PLANES DE SEGURIDAD SOCIAL DE AFILIACI\u00d3N OBLIGATORIA N.C.P.'
  },
  {
    'value' : '850011',
    'label' : 'ENSE\u00d1ANZA PREESCOLAR P\u00daBLICA'
  },
  {
    'value' : '850012',
    'label' : 'ENSE\u00d1ANZA PRIMARIA, SECUNDARIA CIENT\u00cdFICO HUMANISTA Y T\u00c9CNICO PROFESIONAL P\u00daBLICA'
  },
  {
    'value' : '850021',
    'label' : 'ENSE\u00d1ANZA PREESCOLAR PRIVADA'
  },
  {
    'value' : '850022',
    'label' : 'ENSE\u00d1ANZA PRIMARIA, SECUNDARIA CIENT\u00cdFICO HUMANISTA Y T\u00c9CNICO PROFESIONAL PRIVADA'
  },
  {
    'value' : '853110',
    'label' : 'ENSE\u00d1ANZA SUPERIOR EN UNIVERSIDADES P\u00daBLICAS'
  },
  {
    'value' : '853120',
    'label' : 'ENSE\u00d1ANZA SUPERIOR EN UNIVERSIDADES PRIVADAS'
  },
  {
    'value' : '853201',
    'label' : 'ENSE\u00d1ANZA SUPERIOR EN INSTITUTOS PROFESIONALES'
  },
  {
    'value' : '853202',
    'label' : 'ENSE\u00d1ANZA SUPERIOR EN CENTROS DE FORMACI\u00d3N T\u00c9CNICA'
  },
  {
    'value' : '861010',
    'label' : 'ACTIVIDADES DE HOSPITALES Y CL\u00cdNICAS P\u00daBLICAS'
  },
  {
    'value' : '861020',
    'label' : 'ACTIVIDADES DE HOSPITALES Y CL\u00cdNICAS PRIVADAS'
  },
  {
    'value' : '862010',
    'label' : 'ACTIVIDADES DE CENTROS DE SALUD MUNICIPALIZADOS (SERVICIOS DE SALUD P\u00daBLICA)'
  },
  {
    'value' : '862021',
    'label' : 'CENTROS M\u00c9DICOS PRIVADOS (ESTABLECIMIENTOS DE ATENCI\u00d3N AMBULATORIA)'
  },
  {
    'value' : '862022',
    'label' : 'CENTROS DE ATENCI\u00d3N ODONTOL\u00d3GICA PRIVADOS (ESTABLECIMIENTOS DE ATENCI\u00d3N AMBULATORIA)'
  },
  {
    'value' : '862031',
    'label' : 'SERVICIOS DE M\u00c9DICOS PRESTADOS DE FORMA INDEPENDIENTE'
  },
  {
    'value' : '862032',
    'label' : 'SERVICIOS DE ODONT\u00d3LOGOS PRESTADOS DE FORMA INDEPENDIENTE'
  },
  {
    'value' : '869010',
    'label' : 'ACTIVIDADES DE LABORATORIOS CL\u00cdNICOS Y BANCOS DE SANGRE'
  },
  {
    'value' : '869091',
    'label' : 'OTROS SERVICIOS DE ATENCI\u00d3N DE LA SALUD HUMANA PRESTADOS POR EMPRESAS'
  },
  {
    'value' : '869092',
    'label' : 'SERVICIOS PRESTADOS DE FORMA INDEPENDIENTE POR OTROS PROFESIONALES DE LA SALUD'
  },
  {
    'value' : '871000',
    'label' : 'ACTIVIDADES DE ATENCI\u00d3N DE ENFERMER\u00cdA EN INSTITUCIONES'
  },
  {
    'value' : '872000',
    'label' : 'ACTIVIDADES DE ATENCI\u00d3N EN INSTITUCIONES PARA PERSONAS CON DISCAPACIDAD MENTAL Y TOXIC\u00d3MANOS'
  },
  {
    'value' : '873000',
    'label' : 'ACTIVIDADES DE ATENCI\u00d3N EN INSTITUCIONES PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD F\u00cdSICA'
  },
  {
    'value' : '881000',
    'label' : 'ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD'
  },
  {
    'value' : '889000',
    'label' : 'OTRAS ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO'
  },
  {
    'value' : '900004',
    'label' : 'SERVICIOS PRESTADOS POR PERIODISTAS INDEPENDIENTES'
  },
  {
    'value' : '910300',
    'label' : 'ACTIVIDADES DE JARDINES BOT\u00c1NICOS, ZOOL\u00d3GICOS Y RESERVAS NATURALES'
  },
  {
    'value' : '931101',
    'label' : 'HIP\u00d3DROMOS'
  },
  {
    'value' : '949904',
    'label' : 'CONSEJO DE ADMINISTRACI\u00d3N DE EDIFICIOS Y CONDOMINIOS'
  },
  {
    'value' : '951100',
    'label' : 'REPARACI\u00d3N DE COMPUTADORES Y EQUIPO PERIF\u00c9RICO'
  },
  {
    'value' : '951200',
    'label' : 'REPARACI\u00d3N DE EQUIPO DE COMUNICACIONES (INCLUYE LA REPARACI\u00d3N TEL\u00c9FONOS CELULARES)'
  },
  {
    'value' : '952100',
    'label' : 'REPARACI\u00d3N DE APARATOS ELECTR\u00d3NICOS DE CONSUMO (INCLUYE APARATOS DE TELEVISI\u00d3N Y RADIO)'
  },
  {
    'value' : '952900',
    'label' : 'REPARACI\u00d3N DE OTROS EFECTOS PERSONALES Y ENSERES DOM\u00c9STICOS'
  },
  {
    'value' : '960100',
    'label' : 'LAVADO Y LIMPIEZA, INCLUIDA LA LIMPIEZA EN SECO, DE PRODUCTOS TEXTILES Y DE PIEL'
  },
  {
    'value' : '960310',
    'label' : 'SERVICIOS FUNERARIOS'
  },
  {
    'value' : '960320',
    'label' : 'SERVICIOS DE CEMENTERIOS'
  },
  {
    'value' : '990000',
    'label' : 'ACTIVIDADES DE ORGANIZACIONES Y \u00d3RGANOS EXTRATERRITORIALES'
  },
  {
    'value' : '741120',
    'label' : 'SERVICIO NOTARIAL'
  },
  {
    'value' : '741130',
    'label' : 'CONSERVADOR DE BIENES RAICES'
  },
  {
    'value' : '741140',
    'label' : 'RECEPTORES JUDICIALES'
  },
  {
    'value' : '852021',
    'label' : 'SERVICIOS M\u00c9DICOS VETERINARIOS EN FORMA INDEPENDIENTE'
  },
  {
    'value' : '919930',
    'label' : 'SERVICIOS DE INSTITUTOS DE ESTUDIOS, FUNDACIONES, CORPORACIONES DE DESARROLLO (EDUCACI\u00d3N, SALUD)'
  },
  {
    'value' : '293000',
    'label' : 'FABRICACI\u00d3N DE PARTES, PIEZAS Y ACCESORIOS PARA VEHICULOS AUTOMOTORES'
  },
  {
    'value' : '453000',
    'label' : 'VENTA DE PARTES, PIEZAS Y ACCESORIOS PARA VEH\u00cdCULOS AUTOMOTORES'
  }
];
