
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertBlock',
  setup() {

    const message = computed(() => process.env.VUE_APP_ALERTA_CONTENT);

    return {
      message,
    };

  }
});
