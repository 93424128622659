
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import PinInput from '@/Components/Shared/PinInput.vue';
import { authService } from '@/Services/Auth.service';
import Card from '@/Components/Shared/Card.vue';
import { helpers, minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { ErrorManagement } from '@/Common/ErrorManagement';

export default defineComponent({
  name       : 'PinVerificationForm',
  components : {
    PinInput,
    Card,
  },
  setup () {

    return {
      v$: useVuelidate()
    };

  },
  data() {

    return {
      form: {
        code: '',
      }
    };

  },
  validations() {

    return {
      form: {
        code: {
          required  : helpers.withMessage('', required),
          minLength : helpers.withMessage('Código incompleto', minLength(6)),
        },
      }
    };

  },
  computed: {
    ...mapState('auth', [
      'verificationAuth'
    ])
  },
  methods: {
    ...mapActions('session', [
      'setSession'
    ]),
    async onSubmit() {

      try {

        await this.v$.$validate();

        if(!this.v$.$invalid) {

          const response = await authService.checkSignIn(this.form, this.verificationAuth.security_token);
          await this.setSession({
            profile       : response.profile,
            access_token  : response.access_token,
            refresh_token : response.refresh_token,
          });
          this.$router.push({ name: 'list' });

        }

      }catch (error) {

        const pinInput:any = this.$refs.pin;
        new ErrorManagement({ error, callback: (result: any) => {

          if (result.isConfirmed) pinInput.resend();
          if (result.isDismissed) pinInput.clearInput();

        } });

      }

    },
    onChange(e: any, field: string) {

      this.form[field as keyof typeof this.form] = e;

    }
  }

});
