import RegisterShopForm from '@/Components/Shop/RegisterShopForm.component.vue';
import ShopLayout from '@/Components/Layouts/Shop.layout.vue';
import ShopList from '@/Components/Shop/ShopList.component.vue';
import ValidateShopEmail from '@/Components/Shop/ValidateShopEmail.component.vue';

export default {
  path      : '/shop',
  name      : 'shop',
  component : ShopLayout,
  children  : [
    {
      path      : 'list',
      name      : 'list',
      component : ShopList,
      meta      : {
        authorize   : [ 'USER' ],
        breadcrumbs : []
      },
    },
    {
      path      : 'register',
      name      : 'register',
      component : RegisterShopForm,
      meta      : {
        authorize   : [ 'USER' ],
        breadcrumbs : [
          {
            text   : 'Mis Comercios',
            to     : '/shop/list',
            active : false
          },
          {
            text   : 'Agregar Comercio',
            active : true
          },
        ]
      },
    },
    {
      path      : 'validate',
      name      : 'validate',
      component : ValidateShopEmail,
      meta      : {
        authorize   : [ 'USER' ],
        breadcrumbs : [
          {
            text   : 'Mis Comercios',
            to     : '/shop/list',
            active : false,
          },
          {
            text   : 'Agregar Comercio',
            to     : '/shop/register',
            active : false,
          },
          {
            text   : 'Validar Email',
            active : true,
          },
        ]
      },
    },
  ],
};


