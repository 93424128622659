
import { computed, defineComponent, ref } from 'vue';
import Card from '@/Components/Shared/Card.vue';
import { kycService } from '@/Services/Kyc.service';
import { mapActions, useStore } from 'vuex';
import { IKycGetJobIdResponse } from '@/Common/Interfaces/KycJobIdResponse.interface';
import { KycCodeEnum } from '@/Common/Enums/KycCode.enum';

export default defineComponent({
  name       : 'KycWaiting',
  components : {
    Card,
  },
  props: {
    shop: { type: Object, default: () => ({}), required: false }
  },
  setup() {

    const data = ref<IKycGetJobIdResponse>({ job_id: '', request_time: '' });

    const store = useStore();
    const shopConfig = computed(() => store.state.session.shopConfig);

    const getJobId = async () => {

      data.value = await kycService.getJobId({ enroller_code: shopConfig.value.enroller_code, commerce_tax_id: shopConfig.value.commerce_tax_id });

    };

    getJobId();

    return { data };

  },
  computed: {
    supportEmail() {

      return process.env.VUE_APP_SUPPORT_EMAIL || '';

    }
  },
  methods: {
    ...mapActions('app', [
      'setKyc',
    ]),
    refresh() {

      this.setKyc({ code: KycCodeEnum.Finish });

    }
  }
});
