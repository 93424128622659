<template>
  <b-form-group
    :id="`input-group-${id}`"
    :label="label"
    :label-for="`input-${id}`"
  >
    <b-form-input
      :id="`input-${id}`"
      v-model.trim="value"
      type="text"
      :placeholder="placeholder"
      autocomplete="off"
      :state="state"
      @input="onChange"
    />
    <b-form-invalid-feedback :state="errors.length > 0">
      {{ errors.length > 0? errors[0].$message:'' }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { defineComponent } from 'vue';
import { v4 } from 'uuid';

export default defineComponent({
  name  : 'TextInput',
  props : {
    label       : { type: String, default: 'Label', required: false },
    state       : { type: Boolean, required: false },
    errors      : { type: Array, default: ()=>([]), required: false },
    placeholder : { type: String, default: '', required: false },
  },
  emits: [ 'onChange' ],
  data() {

    return {
      value : '',
      id    : v4(),
    };

  },
  methods: {
    onChange(e) {

      this.$emit('onChange', e);

    }
  }
});

</script>
<style scoped>

</style>
