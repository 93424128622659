import Dashboard from '@/Components/Home/Dashboard.component.vue';
import MainLayout from '@/Components/Layouts/Main.layout.vue';

export default {
  path      : '/',
  component : MainLayout,
  redirect  : { name: 'sign-in' },
  children  : [
    {
      path      : '/:commerce_hash/dashboard',
      name      : 'dashboard',
      component : Dashboard,
      meta      : {
        authorize   : [ 'USER' ],
        breadcrumbs : [
          {
            text   : 'Mis Comercios',
            to     : '/shop/list',
            active : false
          },
          {
            text   : 'Dashboard',
            active : true
          },
        ],
      },
    }
  ],
};
