<template>
  <b-form-group
    :id="`input-group-${id}`"
    :label="label"
    :label-for="`input-${id}`"
  >
    <b-form-input
      :id="`input-${id}`"
      v-model.trim="rut"
      type="text"
      placeholder="00000000-0"
      autocomplete="off"
      :state="state"
      @blur="formatRut"
      @input="onChange"
    />
    <b-form-invalid-feedback :state="errors.length > 0">
      {{ errors.length > 0? errors[0].$message:'' }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { defineComponent } from 'vue';
import { format } from 'rut.js';
import { v4 } from 'uuid';

export default defineComponent({
  name  : 'PasswordInput',
  props : {
    label  : { type: String, default: 'RUT', required: false },
    state  : { type: Boolean, required: false },
    errors : { type: Array, default: () => ([]), required: false },
  },
  emits: [ 'onChange' ],
  data() {

    return {
      rut : '',
      id  : v4(),
    };

  },
  methods: {
    onChange(e) {

      this.$emit('onChange', e);

    },
    formatRut() {

      this.rut = this.rut?format(this.rut, { dots: false }):'';
      this.$emit('onChange', this.rut);

    },
  }

});
</script>
<style scoped>

</style>
