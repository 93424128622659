import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "outer-layout",
  class: "bg-light"
}
const _hoisted_2 = { class: "container-fluid main-container" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OuterImage = _resolveComponent("OuterImage")!
  const _component_OuterContent = _resolveComponent("OuterContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_OuterImage, { class: "col-md-5" }),
        _createVNode(_component_OuterContent, { class: "col-md-7" })
      ])
    ])
  ]))
}