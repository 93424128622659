
import { defineComponent } from 'vue';

export default defineComponent({
  name       : 'FooterMenu',
  components : {
  },
  computed: {
    supportEmail() {

      return process.env.VUE_APP_SUPPORT_EMAIL || '';

    }
  },
  mounted() {

    const text = process.env.VUE_APP_FOOTER_TEXT || '';
    const footer = this.$refs.footer as HTMLElement;
    const bice = '<strong> Banco BICE </strong>';
    const newText = text.replace(/Banco BICE/g, bice) + '&copy;';
    footer.innerHTML = newText;

  },
});
