
import { defineComponent } from 'vue';
import OuterImage from '@/Components/Layouts/Partials/OuterImage.partial.vue';
import OuterContent from '@/Components/Layouts/Partials/OuterContent.partial.vue';

export default defineComponent({
  name       : 'OuterLayout',
  components : {
    OuterImage,
    OuterContent
  },
});
