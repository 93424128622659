export enum BalanceType{
  Chargeback='reversa',
  RedPay='redpay',
  Surplus='surplus',
}

export interface IGetBalanceRequest {
  enroller_code: string;
  commerce_tax_id: string;
  statement_type: BalanceType;
}
