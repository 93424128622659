
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { kycService } from '@/Services/Kyc.service';

export function isPdf(file: File) {

  const regex = /^.*\.(pdf|PDF)$/;
  return regex.test(file.name);

}

export default defineComponent({
  props: {
    label       : { type: String, default: 'File' },
    description : { type: String, default: 'Description' },
    name        : { type: String, default: 'File' },
    state       : Boolean,
  },
  emits: [ 'updateState' ],
  setup () {

    return {
      v$: useVuelidate()
    };

  },
  data() {

    return {
      file: '',
    };

  },
  validations () {

    return {
      file: {
        required : helpers.withMessage('', required),
        isPdf    : helpers.withMessage('El archivo debe ser un PDF', isPdf),
      },
    };

  },
  computed: {
    enroller_code() {

      return this.$route.params.enroller_code || this.$store.getters['session/shopConfig'].enroller_code;

    },
    commerce_tax_id() {

      return this.$route.params.commerce_tax_id || this.$store.getters['session/shopConfig'].commerce_tax_id;

    }
  },
  methods: {
    async onSubmit() {

      try{

        await this.v$.$validate();
        if(!this.v$.$invalid && !this.state) {

          await kycService.uploadDocument({
            file            : this.file,
            url             : this.name,
            enroller_code   : this.enroller_code,
            commerce_tax_id : this.commerce_tax_id
          } );
          this.$emit('updateState', this.name);

        }

      }catch (error) {

        console.log(error);

      }

    },
    onChange(event: any) {

      this.file = event.target.files[0];
      this.v$.$validate();

    }
  }
});
