
import { defineComponent } from 'vue';
import DataTable from '../Shared/DataTable.vue';
import KycFileRequestForm from '@/Components/Kyc/KycFileRequestForm.component.vue';
import KycInvalid from '@/Components/Kyc/KycInvalid.component.vue';
import KycInvalidFaculty from '@/Components/Kyc/KycInvalidFaculty.component.vue';
import KycInvalidNotImplemented from '@/Components/Kyc/KycInvalidNotImplemented.component.vue';
import KycInvalidWithoutAction from '@/Components/Kyc/KycInvalidWithoutAction.component.vue';
import KycRequestForm from '@/Components/Kyc/KycRequestForm.component.vue';
import KycRequestSignatureForm from '@/Components/Kyc/KycRequestSignatureForm.component.vue';
import KycWaiting from '@/Components/Kyc/KycWaiting.component.vue';
import { KycCodeEnum } from '@/Common/Enums/KycCode.enum';
import { mapActions } from 'vuex';

export default defineComponent({
  name       : 'RedpayStatement',
  components : {
    DataTable,
    KycRequestForm,
    KycWaiting,
    KycFileRequestForm,
    KycRequestSignatureForm,
    KycInvalid,
    KycInvalidFaculty,
    KycInvalidNotImplemented,
    KycInvalidWithoutAction,
  },
  computed: {
    kyc() {

      return this.$store.getters['app/kyc'];

    },
    KycCodeEnum() {

      return KycCodeEnum;

    }
  },
  mounted() {

    this.setKyc({ code: this.KycCodeEnum.Finish });

  },
  methods: {
    ...mapActions('app', [
      'setKyc',
    ]),
  }
});
