
import { defineComponent } from 'vue';
import Card from '@/Components/Shared/Card.vue';
import RutInput from '@/Components/Shared/RutInput.vue';
import { email, helpers, required } from '@vuelidate/validators';
import { Helper } from '@/Common/Helper';
import { providerService } from '@/Services/Provider.service';
import { shopService } from '@/Services/Shop.service';
import useVuelidate from '@vuelidate/core';
import { IDropdownItem } from '@/Common/Interfaces/DropdownItem.interface';
import { IProvider } from '@/Common/Interfaces/Provider.interface';
import { mapActions } from 'vuex';
import { commonData } from '@/Data/Common.data';

export default defineComponent({
  name       : 'RegisterShopForm',
  components : {
    Card,
    RutInput,
  },
  setup () {

    return {
      v$: useVuelidate()
    };

  },
  data() {

    return {
      ...commonData,
      providers: [ {
        text  : '',
        value : '',
      } ],
      form: {
        collector_code  : '',
        commerce_tax    : '',
        collector_email : '',
      }
    };

  },
  async mounted() {

    try{

      const providers = (await providerService.list()).map((provider: IProvider): IDropdownItem => ({ value: provider.code, text: provider.name }) );
      this.providers = Helper.getSortedData(providers, 'value', true); //providers.sort((a: any, b: any) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }));
      this.form.collector_code = this.providers[0].value;

    }catch (error) {

      console.log(error);

    }

  },
  validations() {

    return {
      form: {
        collector_code: {
          required: helpers.withMessage('', required),
        },
        commerce_tax: {
          required    : helpers.withMessage('', required),
          validateRut : helpers.withMessage('RUT inválido', Helper.validateRut),
        },
        collector_email: {
          required : helpers.withMessage('', required),
          email    : helpers.withMessage('Email inválido', email),
        },
      }
    };

  },
  methods: {
    ...mapActions('auth', [
      'setVerificationAuth'
    ]),
    async onSubmit() {

      try{

        await this.v$.$validate();

        if(!this.v$.$invalid) {

          const response = await shopService.validate(this.form);
          await this.setVerificationAuth(response);
          this.$router.push({ name: 'validate' });

        }

      }catch (error) {

        console.log(error);

      }

    },
    onChangeRut(e: string) {

      this.form.commerce_tax = e;

    }
  }

});
