<template>
  <div>
    <b-form-group class="text-center">
      Te enviamos un código de seguridad a <label class="text-decoration-underline">{{ email }}</label>
    </b-form-group>
    <div class="pin-container">
      <v-otp-input
        ref="otpInput"
        :input-classes="`otp-input ${ state ? 'border-success' : 'border-danger'}`"
        separator=" "
        :num-inputs="6"
        :should-auto-focus="true"
        :is-input-num="true"
        :conditional-class="['one', 'two', 'three', 'four', 'five', 'six']"
        :placeholder="['0', '0', '0', '0', '0', '0']"
        class="otp-input-group"
        @on-change="handleOnChange"
        @on-complete="handleOnComplete"
      />
    </div>
    <b-form-invalid-feedback :state="errorMessage.length > 0" class="text-center">
      {{ errorMessage }}
    </b-form-invalid-feedback>
    <p class="text-center mt-2 font-size-14">
      ¿No recibiste el correo electrónico?,
      <b-link class="text-dark" @click="resend">
        <b>Reenviar código</b>
      </b-link>
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import VOtpInput from 'vue3-otp-input';
import { authService } from '@/Services/Auth.service';
import { mapActions, mapState } from 'vuex';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Swal = require('sweetalert2/dist/sweetalert2.js');

export default defineComponent({
  name       : 'PinInput',
  components : {
    VOtpInput,
  },
  props: {
    email        : { type: String, default: 'example@mail.com', required: false },
    errorMessage : { type: String, default: '', required: true },
  },
  emits: [ 'onComplete', 'onChange' ],
  data() {

    return {
      state : false,
      code  : '',
    };

  },
  computed: {
    ...mapState('auth', [
      'verificationAuth'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'setVerificationAuth'
    ]),
    handleOnChange(e) {

      this.state = e.length === 6;
      this.$emit('onChange', e);

    },
    handleOnComplete(e) {

      this.state = e.length === 6;
      this.$emit('onComplete', e);

    },
    async resend() {

      try {

        const response = await authService.resendCode(this.verificationAuth.security_token);
        this.setVerificationAuth(response);

        this.clearInput();

        await Swal.fire({
          title             : '',
          text              : '¡Código enviado exitosamente!',
          icon              : 'success',
          confirmButtonText : 'OK',
        });

      }catch (error) {

        console.log(error);

      }

    },
    clearInput() {

      this.$refs.otpInput.clearInput();

    }
  }

});
</script>
<style scoped>
.pin-container{
  display: flex;
}
.pin-container>*{
  margin: auto;
}
</style>
