import { Helper } from '@/Common/Helper';
import ApiServiceUriList from '@/Data/ApiServiceUriList.data';
import store from '@/Store/Index';
import { HttpExceptionCodeEnum } from '@/Common/Enums/HttpExceptionCode.enum';
import { IErrorManagement } from '@/Common/Interfaces/ErrorManagement.interface';
import router from '@/Router/Index';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Swal = require('sweetalert2/dist/sweetalert2.js');

export class ErrorManagement {

  config: any = {
    icon              : 'error',
    html              : '',
    showCancelButton  : false,
    showConfirmButton : true,
    allowOutsideClick : false,
  };

  callback: any;

  constructor({ config, error, callback }: IErrorManagement) {

    this.config = config ? config: this.config;
    this.callback = callback;
    this.process(error);

  }

  process(error: any) {

    switch (error.code) {
      case 'ERR_NETWORK':
      case 'ECONNREFUSED':
        this.config.html = '¡No se ha podido establecer la comunicación con el servidor';
        this.show();
        break;
      case 'ERR_BAD_REQUEST':
        this.status(error);
        break;
      default:
        this.config.html = error.message;
        this.show();
        break;
    }

  }

  status(error: any) {

    switch (error.response.status) {
      case 401:
        this.error401(error);
        break;
      case 404:
      case 400:
        this.error400(error);
        break;
      case 406:
        this.error406(error);
        break;
    }

  }

  error406(error: any) {

    const uri = Helper.getUriFromUrl(error.config?.url);

    switch (uri) {
      case ApiServiceUriList.Account.GetStatement:
        store.dispatch('app/setKyc', { code: error.response.data.kyc_status });
        break;
    }

  }

  error400(error: any) {

    const uri = Helper.getUriFromUrl(error.config?.url);

    switch (uri) {
      case ApiServiceUriList.Auth.Logout:
      case ApiServiceUriList.Account.GetBalance:
      case ApiServiceUriList.Account.GetStatement:
      case ApiServiceUriList.Provider.List:
        //seccion de metodos ignorados para mostrar mensajes
        break;
      default:
        this.errorCode(error);
        break;
    }

  }

  error401(error: any) {

    const uri = Helper.getUriFromUrl(error.config?.url);
    switch (uri) {
      case ApiServiceUriList.Auth.PasswordReset:
      case ApiServiceUriList.Auth.SignUp:
      case ApiServiceUriList.Auth.CheckSignIn:
      case ApiServiceUriList.Shop.Validate:
      case ApiServiceUriList.Auth.ResendCode:
        this.callback = (result: any) => {

          if (result.isConfirmed) {

            const back: string = <string>router.options.history.state?.back;
            router.push(back);

          }

        };
        this.config.html = '¡El tiempo habilitado para esta acción ha caducado, por favor vuelve a intentar!';
        this.show();
        break;
    }

  }

  errorCode(error: any) {

    switch (error.response.data?.error_code) {
      case HttpExceptionCodeEnum.OTP_SERVICE003:
        this.config.html = '¡Código de seguridad incorrecto!';
        this.config.cancelButtonText = 'Volver a intentar';
        this.config.confirmButtonText = 'Reenviar código';
        this.config.showCancelButton = true;
        break;
      case HttpExceptionCodeEnum.OTP_SERVICE002:
        this.config.html = '¡Código de seguridad expirado!';
        this.config.cancelButtonText = 'Cancelar';
        this.config.confirmButtonText = 'Reenviar un nuevo código';
        this.config.showCancelButton = true;
        break;
      case HttpExceptionCodeEnum.OTP_SERVICE005:
      case HttpExceptionCodeEnum.OTP_SERVICE006:
        this.config.html = 'No se pudo procesar la información entregada, si piensas que esto es un error, por favor contáctate con el soporte.';
        this.config.confirmButtonText = 'Cerrar';
        this.config.showConfirmButton = true;
        this.config.showCancelButton = false;
        break;
      default:
        this.config.html = error.response?.data.message;
        break;
    }

    this.show();

  }

  show() {

    this.callback ? Swal.fire(this.config).then(this.callback): Swal.fire(this.config);

  }

}
