import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46dabc24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "outer-shop-content" }
const _hoisted_2 = {
  style: {"min-height":"88vh","width":"100%"},
  class: "d-grid align-content-start pt-4"
}
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "m-auto col-md-9 mt-4" }
const _hoisted_6 = { style: {"min-height":"4vh","width":"100%","margin-bottom":"3%"} }
const _hoisted_7 = {
  style: {"height":"100%"},
  class: "primary-600"
}
const _hoisted_8 = { style: {"height":"100%","width":"100%","display":"grid","place-content":"center"} }
const _hoisted_9 = {
  ref: "footer",
  class: "font-size-15"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Breadcrumb),
            _createVNode(_component_router_view)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, null, 512)
        ])
      ])
    ])
  ]))
}