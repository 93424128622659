import FaqList from '@/Components/Faq/FaqList.component.vue';
import MainLayout from '@/Components/Layouts/Main.layout.vue';

export default {
  path      : '/faqs',
  name      : 'faqs',
  component : MainLayout,
  children  : [
    {
      path      : '/faqs/list',
      name      : 'faq-list',
      component : FaqList,
      meta      : {
        authorize   : [ 'USER' ],
        breadcrumbs : [
          {
            text   : 'Mis Comercios',
            to     : '/shop/list',
            active : false
          },
          {
            text   : 'Preguntas Frecuentes',
            active : true
          },
        ]
      },
    },
  ],
};
