
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import AlertBlock from '@/Components/Shared/AlertBlock.vue';
import router from '@/Router/Index';
import store from '@/Store/Index';

export default defineComponent({
  name       : 'App',
  components : {
    AlertBlock,
  },
  computed: {
    ...mapState({
      showLoader : (state: any) => state.app.showLoader,
      bradcast   : (state: any) => state.session.broadcast,
    }),
  },
  created() {

    const broadcast = new BroadcastChannel('tabs_channel');
    this.$store.dispatch('session/setBroadcast', broadcast);

    broadcast.onmessage = async function(event: any) {

      if (event.data.command === 'redirectToSignInAllExceptMe' && !document.hasFocus()) {

        await store.dispatch('session/logout', {});
        await router.push({ name: 'sign-in' });

      }

      if (event.data.command === 'reloadAllExceptMe' && !document.hasFocus()) {

        window.location.reload();

      }

      if (event.data.command === 'refreshCountdownAllExceptMe' && !document.hasFocus()) {

        await store.dispatch('timer/setInactivityTime', new Date().getTime());

      }

    };

  },
});
