import ApiServiceUriList from '@/Data/ApiServiceUriList.data';
import { httpClient } from '@/Common/AxiosClient';
import { HttpClient } from '@/Common/HttpClient';
import { IProvider } from '@/Common/Interfaces/Provider.interface';

class ProviderService {

  constructor(private httpClient: HttpClient) {}

  public async list(): Promise<IProvider[]> {

    const { data } = await this.httpClient.get(ApiServiceUriList.Provider.List);
    return data;

  }

}

export const providerService = new ProviderService(httpClient);
