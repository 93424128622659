import { app } from './App.module';
import { auth } from './Auth.module';
import { common } from './Common.module';
import { session } from './Session.module';
import { timer } from './Timer.module';
import { createStore } from 'vuex';

import VuexPersistence from 'vuex-persist';

export const vuexLocal = new VuexPersistence({
  storage : window.localStorage,
  reducer : (state: any) => ({
    common  : state.common,
    session : state.session
  }),
});

export default createStore({
  state     : {},
  getters   : {},
  mutations : {},
  actions   : {},
  modules   : {
    app,
    common,
    auth,
    session,
    timer,
  },
  plugins: [ vuexLocal.plugin ],
});
