
import { defineComponent } from 'vue';
import PinInput from '@/Components/Shared/PinInput.vue';
import useVuelidate from '@vuelidate/core';
import Card from '@/Components/Shared/Card.vue';
import PasswordInput from '@/Components/Shared/PasswordInput.vue';
import { helpers, required, sameAs, minLength } from '@vuelidate/validators';
import { mapState } from 'vuex';
import { authService } from '@/Services/Auth.service';
import { Helper } from '@/Common/Helper';
import { ErrorManagement } from '@/Common/ErrorManagement';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Swal = require('sweetalert2/dist/sweetalert2.js');

export default defineComponent({
  name       : 'PasswordReset',
  components : {
    PinInput,
    Card,
    PasswordInput,
  },
  setup () {

    return {
      v$: useVuelidate()
    };

  },
  data() {

    return {
      form: {
        code            : '',
        password        : '',
        confirmPassword : '',
      }
    };

  },
  validations() {

    return {
      form: {
        code: {
          required  : helpers.withMessage('', required),
          minLength : helpers.withMessage('Código incompleto', minLength(6)),
        },
        password: {
          required         : helpers.withMessage('', required),
          passwordValidate : helpers.withMessage('', Helper.passwordValidate),
        },
        confirmPassword: {
          required       : helpers.withMessage('', required),
          sameAsPassword : helpers.withMessage('No coincide con la contraseña', sameAs(this.form.password)),
        },
      }
    };

  },
  computed: {
    ...mapState('auth', [
      'verificationAuth'
    ])
  },
  methods: {
    async onSubmit() {

      await this.v$.$validate();
      if(!this.v$.$invalid) {

        try{

          await authService.passwordReset({
            code     : this.form.code,
            password : this.form.password
          }, this.verificationAuth.security_token);

          await Swal.fire({
            title             : '',
            text              : 'Contraseña establecida exitosamente',
            icon              : 'success',
            confirmButtonText : 'OK'
          });

          this.$router.push({ name: 'sign-in' });

        }catch (error: any) {

          const pinInput:any = this.$refs.pin;
          new ErrorManagement({ error, callback: (result: any) => {

            if (result.isConfirmed) pinInput.resend();
            if (result.isDismissed) pinInput.clearInput();

          } });

        }

      }

    },
    onChange(e: any, field: string) {

      this.form[field as keyof typeof this.form] = e;

    }
  }

});
