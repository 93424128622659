export default {
  Account: {
    GetBalance   : '/api/dashboard/balance',
    GetStatement : '/api/dashboard/cartola',
  },
  Auth: {
    SignUp         : '/api/auth/sign-up',
    SignIn         : '/api/auth/sign-in',
    Logout         : '/api/auth/logout',
    Timestamp      : '/api/auth/timestamp',
    PasswordReset  : '/api/auth/password-reset',
    SendCode       : '/api/otp/send-code',
    ResendCode     : '/api/otp/resend-code',
    CheckSignIn    : '/api/auth/check-sign-in',
    RefreshSession : '/api/auth/jwt-refresh',
    Unsubscribe    : '/api/unsubscribe',
  },
  Kyc: {
    Init            : '/api/kyc/init',
    GetJobId        : '/api/kyc/job-id',
    GetDocuments    : '/api/kyc/documents',
    UploadDocument  : '/api/kyc/upload-document',
    GetParticipants : '/api/kyc/legal-representants',
    CheckSignatures : '/api/kyc/check-signatures',
    SendInvitation  : '/api/kyc/invite',
    Restart         : '/api/kyc/retry-init',
  },
  Provider: {
    List: '/api/home/list-collectors',
  },
  Shop: {
    List     : '/api/home/list-commerces',
    Add      : '/api/home/check-code-add-commerce',
    Validate : '/api/home/validate-commerce',
  }
};


