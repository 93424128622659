
import { helpers, minLength, required } from '@vuelidate/validators';
import Card from '@/Components/Shared/Card.vue';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import PinInput from '@/Components/Shared/PinInput.vue';
import { shopService } from '@/Services/Shop.service';
import useVuelidate from '@vuelidate/core';
import { ErrorManagement } from '@/Common/ErrorManagement';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Swal = require('sweetalert2/dist/sweetalert2.js');

export default defineComponent({
  name       : 'RegisterShopForm',
  components : {
    Card,
    PinInput,
  },
  setup () {

    return {
      v$: useVuelidate()
    };

  },
  data() {

    return {
      form: {
        code: '',
      }
    };

  },
  computed: {
    ...mapState('auth', [
      'verificationAuth'
    ]),
  },
  created() {

    if(!this.verificationAuth.security_token) {

      return this.$router.push('/shop/register');

    }

  },
  mounted() {

    if(!this.verificationAuth.security_token)this.$router.push('/shop/register');

  },
  validations() {

    return {
      form: {
        code: {
          required  : helpers.withMessage('', required),
          minLength : helpers.withMessage('Código incompleto', minLength(6)),
        },
      }
    };

  },
  methods: {
    async onSubmit() {

      try{

        await this.v$.$validate();
        if(!this.v$.$invalid) {

          await shopService.add(this.form, this.verificationAuth.security_token);

          await Swal.fire({
            title             : '',
            text              : 'Tu comercio ha sido agregado exitosamente',
            icon              : 'success',
            confirmButtonText : 'OK',
          });

          this.$router.push('/shop/list');

        }

      }catch (error) {

        const pinInput:any = this.$refs.pin;
        new ErrorManagement({ error, callback: (result: any) => {

          if (result.isConfirmed) pinInput.resend();
          if (result.isDismissed) pinInput.clearInput();

        } });

      }

    },
    handleOnChange(e: string) {

      this.form.code = e;

    },
    handleOnComplete(e: string) {

      this.form.code = e;

    }
  }

});
