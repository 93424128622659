
import { defineComponent } from 'vue';
import { shopService } from '@/Services/Shop.service';
import ShopCard from '@/Components/Shop/Partials/ShopCard.partial.vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name       : 'ShopList',
  components : {
    ShopCard,
  },
  data() {

    return {
      showLoader   : false,
      query        : '',
      items        : [ {} ],
      searchResult : [ {} ],
    };

  },
  async mounted() {

    try{

      this.items = await shopService.list();
      await this.setShopList(this.items);
      this.search();

      this.showLoader = true;

    }catch (error) {

      console.log('No se ha podido obtener la lista de comercios');

    }

  },
  methods: {
    ...mapActions('session', [
      'setShopConfig',
      'setShopList',
    ]),
    search() {

      const query = this.query.toLowerCase();
      this.searchResult = [ ...this.items ].filter( (shop: any) => {

        switch (true) {
          case String(shop.enroller_uuid).toLowerCase().includes(query):
          case String(shop.enroller_code).toLowerCase().includes(query):
          case String(shop.enroller_name).toLowerCase().includes(query):
          case String(shop.commerce_uuid).toLowerCase().includes(query):
          case String(shop.commerce_tax_id).toLowerCase().includes(query):
          case String(shop.commerce_tax_email).toLowerCase().includes(query):
          case String(shop.commerce_name).toLowerCase().includes(query):
            return shop;
        }

      });

    },
    selectShop(shop: any) {

      this.setShopConfig(shop);
      this.$router.push({ name: 'dashboard', params: { commerce_hash: shop.commerce_hash } });

    }
  }

});
