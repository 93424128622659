
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { authService } from '@/Services/Auth.service';
import useVuelidate from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';
import Card from '@/Components/Shared/Card.vue';
import PasswordInput from '@/Components/Shared/PasswordInput.vue';
import TextInput from '@/Components/Shared/TextInput.vue';
import jwt_decode from 'jwt-decode';
import { commonData } from '@/Data/Common.data';
// import ApiServiceUriList from '@/Data/ApiServiceUriList.data';
// import { AxiosClient } from '@/Common/AxiosClient';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require('crypto-js');

export default defineComponent({
  name       : 'LoginForm',
  components : {
    PasswordInput,
    TextInput,
    Card,
  },
  setup () {

    return {
      v$: useVuelidate()
    };

  },
  data() {

    return {
      ...commonData,
      form: {
        email    : '',
        password : '',
      }
    };

  },
  computed: {
    broadcast() {

      return this.$store.getters['session/broadcast'];

    },
    session() {

      return this.$store.getters['session/session'];

    }
  },
  validations () {

    return {
      form: {
        email: {
          required : helpers.withMessage('', required),
          email    : helpers.withMessage('Correo electrónico inválido', email),
        },
        password: {
          required: helpers.withMessage('', required),
        },
      },
    };

  },
  methods: {
    ...mapActions('auth', [
      'setVerificationAuth'
    ]),
    ...mapActions('session', [
      'setSession'
    ]),
    async onSubmit() {

      const password = this.form.password;
      try {

        await this.v$.$validate();
        if(!this.v$.$invalid) {

          const passphrase = process.env.VUE_APP_PASSPHRASE_FRONT;
          const { timestamp } = await authService.timestamp();

          this.form.password = CryptoJS.AES.encrypt(this.form.password + '|' + timestamp, `${passphrase}${timestamp}`).toString();

          const response = await authService.signIn({ ...this.form, timestamp });
          if(!response.require_security_code) {

            // console.log('session_token', this.session.access_token.trim());
            // if(this.session.access_token) {
            //
            //   const session = JSON.parse(JSON.stringify(this.session));
            //
            //   await this.setSession({
            //     profile       : response.profile,
            //     access_token  : response.access_token,
            //     refresh_token : response.refresh_token,
            //   });
            //
            //   // const httpClient = new AxiosClient({ baseURL: String(process.env.VUE_APP_BASE_URL) });
            //   // await httpClient.post(ApiServiceUriList.Auth.Logout, { authorization_refresh: session.refresh_token, authorization_access: session.access_token, userId: session.profile.uuid });
            //
            // }

            await this.setSession({
              profile       : response.profile,
              access_token  : response.access_token,
              refresh_token : response.refresh_token,
            });

            const broadcast = new BroadcastChannel('tabs_channel');
            broadcast.postMessage({ command: 'reloadAllExceptMe' });

            this.$router.push({ name: 'list' });

          }

          if(response.require_security_code) {

            await this.setVerificationAuth({
              security_token : response.security_token,
              payload        : jwt_decode(response.security_token)
            });

            this.$router.push('/site/verification');

          }

        }

      }catch (error) {

        this.form.password = password;
        console.log(error);

      }

    },
    onChange(e: any, field: string) {

      this.form[field as keyof typeof this.form] = e;

    },
  }

});
