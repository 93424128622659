import { validate } from 'rut.js';

export class Helper {

  public static validateRut(text: string) {

    return validate(text);

  }

  public static getUriFromUrl(url: string) {

    // return url ? url.split('/').splice(0, 4).join('/') : '';
    const uri = url ? url.split('/').splice(0, 4).join('/'): '';
    return uri.split('?').shift();

  }

  public static passwordValidate(password: string) {

    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$&*.])(?=.*[a-zA-Z]).{8,12}$/;
    if(Array.from(password).length === 0)return true;
    return regex.test(password.trim());

  }

  public static getSortedData(data: any, prop: string, isAsc: boolean) {

    return data.sort((a: any, b: any) => (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1));

  }

}
