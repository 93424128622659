
import { computed, ComputedRef, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import Card from '@/Components/Shared/Card.vue';
import { useStore } from 'vuex';
import { kycService } from '@/Services/Kyc.service';
import { IParticipant } from '@/Common/Interfaces/IParticipant.interface';
import { SignOptTypeEnum } from '@/Common/Enums/SignOptType.enum';
import { commonData } from '@/Data/Common.data';
import { TaskTimer } from 'tasktimer';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Swal = require('sweetalert2/dist/sweetalert2.js');

export default defineComponent({
  name       : 'KycRequestSignatureForm',
  components : {
    Card,
  },
  setup() {

    const store = useStore();

    const participants = ref<IParticipant[]>([]);
    const signatureStatus = ref<IParticipant[]>([]);

    const sign_opt = ref<SignOptTypeEnum>(SignOptTypeEnum.All);
    const showContent = ref(true);

    const signOptTypeEnum: ComputedRef<any> = computed(() => SignOptTypeEnum);
    const session: ComputedRef<any> = computed(() => store.getters['session/session']);
    const enroller_code: ComputedRef<string> = computed(() => store.getters['session/shopConfig'].enroller_code);
    const commerce_tax_id: ComputedRef<string> = computed(() => store.getters['session/shopConfig'].commerce_tax_id);

    const timer = computed(() => store.getters['app/signatureState']);

    const getParticipants = async () => {

      try {

        showContent.value = false;
        const response = await kycService.getParticipants({ enroller_code: enroller_code.value, commerce_tax_id: commerce_tax_id.value });

        participants.value = response.representants;
        sign_opt.value = response.sign_opt;

        showContent.value = true;

      }catch (error) {

        showContent.value = false;
        console.log(error);

      }

    };

    const checkSignatures = async () => {

      try {

        const response = await kycService.checkSignatures({ enroller_code: enroller_code.value, commerce_tax_id: commerce_tax_id.value });
        signatureStatus.value = response.representants;

      }catch (error) {

        console.log(error);

      }

    };

    const onSignature = async (url: string) => {
    
      Swal.fire({        
        html              : 'Proceso de firma en curso...',
        timerProgressBar  : true,
        showCloseButton   : true,
        allowOutsideClick : false,
        allowEscapeKey    : false,
        didOpen           : () => {

          Swal.showLoading();
          /* const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Swal.getTimerLeft()}`;
          }, 100); */

        },
        /* willClose: () => {
          clearInterval(timerInterval);
        } */
      }).then((result: any) => {
                
        console.log('I was closed by the timer', result);        

      });

      window.open(url, '_blank');

    };

    const sendInvitation = (participant: IParticipant) => {

      const html = `
        <p><b>Enviar invitación a: </b></p>
        <p class="mb-2">${participant.full_name}</p>
        <span class="text-secondary font-size-15">(RUT: ${participant.rut})</span>
      `;

      Swal.fire({
        icon            : 'info',
        html            : html,
        input           : 'email',
        inputAttributes : {
          autocapitalize: 'off',
        },
        inputPlaceholder    : commonData.placeholder.defaultEmail,
        showCancelButton    : true,
        confirmButtonText   : 'Enviar',
        cancelButtonText    : 'Cancelar',
        showLoaderOnConfirm : true,
        validationMessage   : 'Correo electrónico inválido',
      }).then(async (result: any) => {

        if (result.isConfirmed) {

          try {

            await kycService.sendInvitation({
              enroller_code   : enroller_code.value,
              commerce_tax_id : commerce_tax_id.value,
              full_name       : participant.full_name,
              rut             : participant.rut,
              email           : result.value,
            });

            Swal.fire('Invitación enviada!', '', 'success');

          }catch (error) {

            console.log(error);

          }

        }

      });

    };

    onMounted(async () => {

      try{

        await getParticipants();

      }catch (error) {

        console.error(error);

      }            
          
    });

    if(timer.value.stop)timer.value.stop();

    const seconds = +(process.env.VUE_APP_SIGNATURE_CHECK_INTERVAL || 10);
    store.dispatch('app/setSignatureState', new TaskTimer(seconds * 1000));
    timer.value.add(async () => {          

      if(participants.value.length == 0) return;

      let isChangedState = false;
      for (const participant of participants.value) {

        const signature = signatureStatus.value.find(i => i.rut == participant.rut);

        if(signature && signature.is_signed != participant.is_signed) {

          isChangedState = true;
          continue;

        }
        
      }

      if(isChangedState) {
      
        Swal.fire({          
          html      : 'Firma realizada con éxito',
          timer     : 3000,
          buttons   : false,
          icon      : 'success',
          willClose : () => {

            location.reload();

          }                
        }).then((result: any) => {

          console.log('I was closed by the timer', result);
          /* Read more about handling dismissals below */          
          // location.reload();

        });        

      } else {

        await checkSignatures();

      }          
  
    }).start();

    onUnmounted(() => {

      if(timer.value.stop)timer.value.stop();

    });

    return {
      participants,
      enroller_code,
      commerce_tax_id,
      sendInvitation,
      session,
      sign_opt,
      signOptTypeEnum,
      showContent,
      onSignature,
    };

  },
});
