import ApiServiceUriList from '@/Data/ApiServiceUriList.data';
import { httpClient } from '@/Common/AxiosClient';
import { HttpClient } from '@/Common/HttpClient';
import { ICheckCodeRequest } from '@/Common/Interfaces/CheckCodeRequest.interface';
import { ICreateUserPayload } from '@/Common/Interfaces/CreateUserPayload.interface';
import { IPasswordResetRequest } from '@/Common/Interfaces/PasswordResetRequest.interface';
import { ISecurityJwt } from '@/Common/Interfaces/SecurityJwt.interface';
import { ISendCodeRequest } from '@/Common/Interfaces/SendCodeRequest.interface';
import { ISendCodeResponse } from '@/Common/Interfaces/SendCodeResponse.interface';
import { ISignInRequest } from '@/Common/Interfaces/SignInRequest.interface';
import jwt_decode from 'jwt-decode';
import store from '@/Store/Index';

class AuthService {

  constructor(private httpClient: HttpClient) {}

  public async signUp(payload: ICreateUserPayload, security_token: string): Promise<void> {

    await this.httpClient.post(ApiServiceUriList.Auth.SignUp, { ...payload, security_token });

  }

  public async signIn(payload: ISignInRequest): Promise<any> {

    const { data } = await this.httpClient.post(ApiServiceUriList.Auth.SignIn, payload);
    return data;

  }

  public async logout(): Promise<void> {

    const refresh_token = store.getters['session/session'].refresh_token;
    const access_token = store.getters['session/session'].access_token;
    const profile = store.getters['session/session'].profile;

    await this.httpClient.post(ApiServiceUriList.Auth.Logout, { authorization_refresh: refresh_token, authorization_access: access_token, userId: profile.uuid });
    await store.dispatch('session/logout', {});

  }

  public async timestamp() {

    const { data } = await this.httpClient.get(ApiServiceUriList.Auth.Timestamp);
    return data;

  }

  public async refreshSession(refreshToken: string): Promise<any> {

    const { data } = await this.httpClient.post(ApiServiceUriList.Auth.RefreshSession, { authorization_refresh: refreshToken });
    return data;

  }

  public async passwordReset(payload: IPasswordResetRequest, security_token: string): Promise<void> {

    await this.httpClient.post(ApiServiceUriList.Auth.PasswordReset, { ...payload, security_token });

  }

  public async sendCode(payload: ISendCodeRequest): Promise<ISendCodeResponse> {

    const { data } : { data: ISecurityJwt } = await this.httpClient.post(ApiServiceUriList.Auth.SendCode, payload);

    return {
      security_token : data.security_token,
      payload        : jwt_decode(data.security_token)
    };

  }

  public async resendCode(security_token: string): Promise<ISendCodeResponse> {

    const { data } : { data: ISecurityJwt } = await this.httpClient.post(ApiServiceUriList.Auth.ResendCode, { security_token });

    return {
      security_token : data.security_token,
      payload        : jwt_decode(data.security_token)
    };

  }

  public async checkSignIn(payload: ICheckCodeRequest, security_token: string): Promise<any> {

    const { data } = await this.httpClient.post(ApiServiceUriList.Auth.CheckSignIn, { ...payload, security_token });
    return data;

  }

  public async unsubscribe(hash: string): Promise<any> {

    const { data } = await this.httpClient.get(`${ApiServiceUriList.Auth.Unsubscribe}/${hash}`);
    return data;

  }

}

export const authService = new AuthService(httpClient);
