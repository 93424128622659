
import { computed, defineComponent, ref, watch } from 'vue';
import { TaskTimer } from 'tasktimer';
import { authService } from '@/Services/Auth.service';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Swal = require('sweetalert2/dist/sweetalert2.js');

export default defineComponent({
  name: 'TimerComponent',
  setup() {

    const router = useRouter();
    const store = useStore();

    const session = computed(() => store.state.session.session);
    const inactivityTime = computed(() => store.state.timer.inactivityTime);
    const timerLabel = computed(() => store.state.timer.timerLabel);
    const timer = computed(() => store.state.timer.timer);
    const shownRefreshModal = computed(() => store.state.timer.shownRefreshModal);
    const shownWarningBeforeLogout = computed(() => process.env.VUE_APP_SHOW_WARNING_BEFORE_LOGOUT === 'true');
    const beforeLogoutSeconds = computed(() => Number(process.env.VUE_APP_BEFORE_LOGOUT_SECONDS));
    const labelColor = ref('text-secondary');

    // const decoded: any = jwt_decode(session.value.refresh_token);
    const init = moment();
    const deadline = moment(session.value.deadline).utc();

    const logout = async () => {

      try{

        console.log('se envia la peticion para logout');
        await authService.logout();

      }catch (error) {

        console.log(error);

      }

      const broadcast = new BroadcastChannel('tabs_channel');
      broadcast.postMessage({ command: 'redirectToSignInAllExceptMe' });

      await router.push({ name: 'sign-in' });

    };

    const refresh = async () => {

      try{

        const response = await authService.refreshSession(session.value.refresh_token);

        await store.dispatch('session/setSession', {
          ...session.value,
          access_token: response.access_token,
        });

        await store.dispatch('app/setInactivityTime', new Date().getTime());

      } catch (error) {

        console.error(error);

      }

    };

    if(init.isAfter(deadline)) {

      logout().then();

    }

    const getDuration = () => {

      const decoded: any = jwt_decode(session.value.refresh_token);
      const init = moment();
      const end = moment.unix(decoded.exp).utc();
      const seconds = moment.duration(end.diff(init)).asSeconds();

      const maxInactivityTime = Number(process.env.VUE_APP_INACTIVITY_TIME);
      const durationInSeconds = seconds < maxInactivityTime ? seconds : maxInactivityTime;

      const deadline = init.clone().add(durationInSeconds, 'seconds');

      store.dispatch('session/setSession', {
        ...session.value,
        deadline: deadline.format(),
      });

      return durationInSeconds;

    };

    const lifecycle = (duration: number) => {

      if(timer.value.stop)timer.value.stop();

      const limit = duration;
      const showWarningOn = limit - beforeLogoutSeconds.value;

      let minutes;
      let seconds;
      store.dispatch('timer/setTimer', new TaskTimer(1000));
      timer.value.add(async (task: any) => {

        minutes = parseInt(String(duration / 60), 10);
        seconds = parseInt(String(duration % 60), 10);

        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        await store.dispatch('timer/setTimerLabel', `${minutes}:${seconds}`);

        --duration;

        if(showWarningOn < task.currentRuns && !shownRefreshModal.value && shownWarningBeforeLogout.value) {

          await store.dispatch('timer/setShownRefreshModal', true);

          Swal.fire({
            title             : '¿Desea continuar?',
            showCancelButton  : true,
            confirmButtonText : 'Si',
            cancelButtonText  : 'No',
            allowOutsideClick : false,
            backdrop          : `
              rgba(0,0,123,0.4)
            `
          }).then(async (result: any) => {

            await store.dispatch('timer/setShownRefreshModal', false);

            if (result.isConfirmed) {

              await refresh();

            } else {

              await logout();
              timer.value?.stop();

            }

          });

        }

        if(limit < task.currentRuns) {

          Swal.close();
          await store.dispatch('timer/setShownRefreshModal', false);

          await logout();

          timer.value.stop();

        }

      }).start();

    };

    watch(inactivityTime, () => {

      store.dispatch('timer/setShownRefreshModal', false);
      const duration = getDuration();
      lifecycle(duration);

    });

    return {
      labelColor,
      timerLabel,
    };

  },
});
